import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from 'react';

import * as media from '@wb/shared/theme/custom-media';

export const ViewportContext = createContext();

/**
 * Combines a breakpoint with its respective media watcher.
 */
function reduceBreakpointMatchers(breakpointWatchers = []) {
  return breakpointWatchers.reduce(
    (breakpoints, watcher) => ({
      ...breakpoints,
      [watcher.name]: !!watcher.query.matches,
    }),
    {}
  );
}

/**
 * Transforms the breakpoint watchers into a map where each key is
 * the name of the breakpoint and the value whether or not that
 * breakpoint matches with the current viewport.
 */
function getBreakpointMatches(mediaWatchers = {}) {
  const clientBreakpointMatches = reduceBreakpointMatchers(
    mediaWatchers.clientMatchers
  );

  return clientBreakpointMatches;
}

export function ViewportProvider(props) {
  const mediaWatchers = useMemo(() => {
    const breakpoints = {
      extraSmall: media.extraSmall,
      small: media.small,
      medium: media.medium,
      gteSmall: media.gteSmall,
      lteSmall: media.lteSmall,
    };

    const clientMatchers = Object.entries(breakpoints).map(
      ([breakpoint, query]) => ({
        name: breakpoint,
        query:
          typeof window.matchMedia !== 'undefined'
            ? window.matchMedia(query)
            : {},
      })
    );

    return {
      clientMatchers,
    };
  }, []);

  const [breakpoint, setBreakpoint] = useState(
    getBreakpointMatches(mediaWatchers)
  );

  const update = useCallback(() => {
    setBreakpoint(getBreakpointMatches(mediaWatchers));
  }, [mediaWatchers]);

  useEffect(() => {
    for (const { query } of mediaWatchers.clientMatchers) {
      query.addListener?.(update);
    }

    return () => {
      for (const { query } of mediaWatchers.clientMatchers) {
        query.removeListener?.(update);
      }
    };
  }, [mediaWatchers, update]);

  return <ViewportContext.Provider value={breakpoint} {...props} />;
}

export function useViewport() {
  return useContext(ViewportContext);
}

export default useViewport;
