import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isFuture from 'date-fns/isFuture';
import getYear from 'date-fns/getYear';

export const DATE_PATTERN = 'dd/MM/yyyy';
export const DATE_TIME_PATTERN = 'dd/MM/yyyy HH:mm';
export const MIN_YEAR = 1899;

export function validDate(message) {
  return this.test('validDate', message, function (value) {
    const date = parse(value, DATE_PATTERN, new Date());

    return (
      (isValid(date) && getYear(date) >= MIN_YEAR) || this.createError(message)
    );
  });
}

export function maxTodayDate(message) {
  return this.test('maxTodayDate', message, function (value) {
    const date = parse(value, DATE_PATTERN, new Date());

    return !isFuture(date) || this.createError(message);
  });
}

export function validDateTime({ message, ref }) {
  return this.test('validDateTime', message, function (value) {
    const dateString = this.resolve(ref);
    const date = parse(`${dateString} ${value}`, DATE_TIME_PATTERN, new Date());

    return isValid(date) || this.createError(message);
  });
}

export function maxTodayDateTime({ message, ref }) {
  return this.test('maxTodayDateTime', message, function (value) {
    const dateString = this.resolve(ref);
    const date = parse(`${dateString} ${value}`, DATE_TIME_PATTERN, new Date());

    return !isFuture(date) || this.createError(message);
  });
}
