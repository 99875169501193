import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import useUser from '@wb/shared/state/user/useUser';

import NotFound from '../../pages/not-found/NotFound';
import GenericError from '../../pages/generic-error/GenericError';

export class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorUrl: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { pathname, search } = props.location;
    const url = `${pathname}${search}`;

    if (state?.errorUrl && state?.errorUrl !== url) {
      return { error: null, errorUrl: null };
    }
    return {};
  }

  componentDidCatch(error, info) {
    const { pathname, search } = this.props.location;
    const url = `${pathname}${search}`;

    process.env.NODE_ENV === 'development' &&
      console.error('App error: ', error, info);
    this.setState({ errorUrl: url, error });
  }

  render() {
    const { children } = this.props;
    const error = this.state?.error;

    if (error) {
      return React.cloneElement(children, { error });
    }

    return children;
  }
}

ErrorCatcher.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
  children: PropTypes.node,
};

export function withErrorCatcher(Component) {
  const Wrapped = ({ location, ...remainingProps }) => (
    <ErrorCatcher location={location}>
      <Component {...remainingProps} />
    </ErrorCatcher>
  );

  const name = Component.displayName || Component.name;

  Wrapped.displayName = name ? `WithErrorCatcher(${name})` : 'WithErrorCatcher';

  Wrapped.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }),
  };

  return withRouter(Wrapped);
}

const NotFoundError = ({ error, children, errorComponent: NotFoundError }) => {
  const status = error?.status || error?.response?.status;
  const isNotFound = status && (status === 400 || status === 404);
  return isNotFound ? <NotFoundError /> : children;
};
export const NotFoundErrorBoundary = withErrorCatcher(NotFoundError);

export const Error = ({ error, children, errorComponent: ErrorComponent }) => {
  const status = error?.status || error?.response?.status;
  const isNotFound =
    status && (status === 400 || status === 404 || status === 500);

  const isUnauthorized = status && (status === 401 || status === 403);

  if (isUnauthorized) return children;

  if (isNotFound) {
    return ErrorComponent ? <ErrorComponent /> : <NotFound />;
  }

  return error ? (
    ErrorComponent ? (
      <ErrorComponent />
    ) : (
      <GenericError />
    )
  ) : (
    children
  );
};
Error.propTypes = {
  error: PropTypes.any,
  children: PropTypes.node,
  errorComponent: PropTypes.func,
};

export const ErrorBoundary = withErrorCatcher(Error);

export const GenericErrorBoundary = withErrorCatcher(
  ({ error, children, errorComponent: ErrorComponent }) => {
    const status = error?.status || error?.response?.status;
    const isNotFound = status && (status === 400 || status === 404);
    const isUnauthorized = status && (status === 401 || status === 403);

    if (isUnauthorized) return children;

    if (isNotFound) {
      return ErrorComponent ? <ErrorComponent /> : <NotFound />;
    }

    return error ? (
      ErrorComponent ? (
        <ErrorComponent />
      ) : (
        <GenericError />
      )
    ) : (
      children
    );
  }
);

export const UnauthorizedErrorBoundary = withErrorCatcher(
  ({ error, children, errorComponent: ErrorComponent }) => {
    const status = error?.status || error?.response?.status;
    const isUnauthorized = status && (status === 401 || status === 403);

    const {
      actions: { signOut },
    } = useUser();

    useEffect(() => {
      if (isUnauthorized) signOut();
    }, [signOut, isUnauthorized]);

    return error ? <ErrorComponent /> : children;
  }
);

NotFoundError.propTypes = {
  error: PropTypes.any,
  children: PropTypes.node,
  errorComponent: PropTypes.func,
};

GenericErrorBoundary.propTypes = {
  error: PropTypes.any,
  children: PropTypes.node,
  errorComponent: PropTypes.node,
};
