import React, { useState, useEffect, useRef } from 'react';
import { Global, css } from '@emotion/react';

const disabledFocusOutlineStyles = css`
  ::-moz-focus-inner {
    border: 0;
  }

  :focus {
    outline: 0;
  }

  input:focus + label,
  input:active + label {
    outline: 0;
  }

  input:focus + label::after,
  input:active + label::after {
    outline: 0;
  }
`;

const RECOGNIZED_NAV_KEYS = [
  'Tab',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'ArrowLeft',
];

export default function useToggleKeyboardStyles() {
  const isKeyboardNav = useRef(true);
  const [disableOutlineStyles, setDisableOutlineStyles] = useState(false);

  useEffect(() => {
    const onMouseDown = () => {
      isKeyboardNav.current = false;
    };

    const onKeyDown = (event) => {
      if (RECOGNIZED_NAV_KEYS.includes(event.key)) {
        isKeyboardNav.current = true;
      }
    };

    const onFocusIn = () => {
      setDisableOutlineStyles(!isKeyboardNav.current);
    };

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('focusin', onFocusIn);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('focusin', onFocusIn);
    };
  }, []);

  return {
    globalStyles: disableOutlineStyles ? (
      <Global styles={disabledFocusOutlineStyles} />
    ) : null,
  };
}
