import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import languageDetector from '@wb/shared/utils/languageDetector';

const options = {
  detection: {
    order: [
      'overload',
      'navigator',
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },
  fallbackLng: 'en',
  load: 'languageOnly',
  ns: [],
  defaultNS: 'components',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
};

if (process && !process.release) {
  const overloadedLanguageDetector = new LanguageDetector();
  overloadedLanguageDetector.addDetector(languageDetector);
  i18n
    // load translation using http -> see /public/locales
    .use(Backend)
    // detect user language
    .use(overloadedLanguageDetector)
    .use(initReactI18next);
}

if (!i18n.isInitialized) {
  i18n.init(options);
}
