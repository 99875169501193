export const RADIO_TYPE = 'radio';
export const DATE_TIME = 'datetime';
export const SELECT_TYPE = 'select';
export const TEXT_AREA_TYPE = 'textarea';
export const EMAIL_TYPE = 'email';
export const TEXT_TYPES = ['text', 'tel'];
export const FILE_TYPE = 'file';
export const SUBSIDIARY_SELECT = 'organizational-subsidiaries';

const INPUT_TYPES = [
  ...TEXT_TYPES,
  RADIO_TYPE,
  SELECT_TYPE,
  TEXT_AREA_TYPE,
  DATE_TIME,
  EMAIL_TYPE,
  FILE_TYPE,
  SUBSIDIARY_SELECT,
];

export default INPUT_TYPES;
