/**
 * There are two type of constants exported here:
 * - simple media query strings (to use, for example, with window.matchMedia())
 * - media query strings prefixed with @media (for easier use on template literals)
 */

/* Simple */
export const extraSmall = '(max-width: 37.5em)'; // <= 600
export const small = '(min-width: 37.56em) and (max-width: 63.94em)'; // >= 601px && <= 1023
export const medium = '(min-width: 64em)'; // >= 1024
export const gteSmall = '(min-width: 37.56em)'; // >= 601px
export const lteSmall = '(max-width: 63.94em)'; // <= 1023px
export const bgSmall = '(min-width: 81.24em)'; // <= 1300px

/* Prefixed with @media */
export const extraSmallMedia = `@media ${extraSmall}`; // <= 600px
export const smallMedia = `@media ${small}`; // >= 601px && <= 1023
export const mediumMedia = `@media ${medium}`; // >= 1024
export const gteSmallMedia = `@media ${gteSmall}`; // >= 768px
export const lteSmallMedia = `@media ${lteSmall}`; // <= 1023px
export const bgSmallMedia = `@media ${bgSmall}`; // <= 1300px
