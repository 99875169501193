export const initialState = {
  error: null,
  isLoading: false,
  user: null,
  authToken: null,
  challenge: null,
  timeout: null,
};

export const ReducerActionTypes = {
  RESET_USER: 'RESET_USER',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_MFA_CHALLENGE_SHOW: 'LOGIN_MFA_CHALLENGE_SHOW',
  LOGIN_MFA_CHALLENGE_SUBMITTING: 'LOGIN_MFA_CHALLENGE_SUBMITTING',
  LOGIN_MFA_CHALLENGE_FAILURE: 'LOGIN_MFA_CHALLENGE_FAILURE',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  COGNITO_USER_REQUEST: 'COGNITO_USER_REQUEST',
  COGNITO_USER_SUCCESS: 'COGNITO_USER_SUCCESS',
  COGNITO_USER_FAILURE: 'COGNITO_USER_FAILURE',
  CHOOSE_PASSWORD_REQUEST: 'CHOOSE_PASSWORD_REQUEST',
  CHOOSE_PASSWORD_SUCCESS: 'CHOOSE_PASSWORD_SUCCESS',
  CHOOSE_PASSWORD_FAILURE: 'CHOOSE_PASSWORD_FAILURE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  SSO_LOGIN: 'SSO_LOGIN',
  UPDATE_USER: 'UPDATE_USER',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ReducerActionTypes.LOGIN_REQUEST:
    case ReducerActionTypes.LOGIN_MFA_CHALLENGE_SUBMITTING:
      return {
        ...state,
        isLoading: true,
        error: null,
        user: initialState.user,
        authToken: initialState.authToken,
      };
    case ReducerActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        user: initialState.user,
        authToken: initialState.authToken,
        challenge: initialState.challenge,
      };
    case ReducerActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: initialState.error,
        challenge: initialState.challenge,
        user: {
          username: action.payload?.username,
        },
        authToken: action.payload?.signInUserSession?.accessToken?.jwtToken,
      };
    case ReducerActionTypes.LOGIN_MFA_CHALLENGE_SHOW:
      return {
        ...state,
        isLoading: false,
        error: initialState.error,
        challenge: {
          user: action.payload.user,
          codeDestination:
            action.payload.user?.challengeParam?.CODE_DELIVERY_DESTINATION,
        },
        timeout: action.payload.timeout,
      };
    case ReducerActionTypes.LOGIN_MFA_CHALLENGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        user: initialState.user,
        authToken: initialState.authToken,
      };
    case ReducerActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: initialState.error,
      };
    case ReducerActionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ReducerActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: initialState.error,
        user: initialState.user,
        authToken: initialState.authToken,
      };
    case ReducerActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: initialState.error,
      };
    case ReducerActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ReducerActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: initialState.error,
        user: {
          username: action.payload?.username,
        },
      };
    case ReducerActionTypes.RESET_USER:
      return {
        ...initialState,
      };
    case ReducerActionTypes.COGNITO_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ReducerActionTypes.COGNITO_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ReducerActionTypes.COGNITO_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ReducerActionTypes.CHOOSE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ReducerActionTypes.CHOOSE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: initialState.error,
        user: {
          username: action.payload?.username,
        },
        authToken: action.payload?.signInUserSession?.accessToken?.jwtToken,
      };
    case ReducerActionTypes.CHOOSE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ReducerActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ReducerActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ReducerActionTypes.UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    default:
      return state;
  }
}

export default reducer;
