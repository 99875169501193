const translationMapper = (step) => {
  switch (step) {
    case 'identification':
      return 'wb-details';
    case 'subject':
    case 'case':
      return 'disclosure-details';
  }
};

export default translationMapper;
