import React from 'react';
import PropTypes from 'prop-types';

import { NativeHeading } from './Heading.styles';

export const VARIANT_TAG_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  large: 'p',
  subtitle: 'p',
};

const Heading = (props) => {
  const { children, variant = 'h1', ...remainingProps } = props;

  return (
    <NativeHeading
      as={VARIANT_TAG_MAPPING[variant]}
      variant={variant}
      {...remainingProps}
    >
      {children}
    </NativeHeading>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'large', 'subtitle']),
};

export default Heading;
