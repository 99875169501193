import React from 'react';
import { useTranslation } from 'react-i18next';

import PageStatus from '../../components/page-status/PageStatus';

import NotFoundImage from './NotFoundImage';

function NotFound() {
  const { t } = useTranslation('shared-components');

  return (
    <PageStatus image={<NotFoundImage />} title={t('error-page.not-found')} />
  );
}

export default NotFound;
