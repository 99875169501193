import React from 'react';
import PropTypes from 'prop-types';

import { Div, DotSpan } from './Loader.styles';

// TODO: Replace when designs arrive
function Loader({ size = 'medium', ...remainingProps }) {
  return (
    <Div data-testid="loading" {...remainingProps}>
      <DotSpan size={size} />
      <DotSpan size={size} />
      <DotSpan size={size} />
    </Div>
  );
}

Loader.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

export default Loader;
