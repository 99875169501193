import React, {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import disclosureFormBuilder, {
  sanitizeSubmitData,
} from '@wb/frontoffice/utils/disclosureFormBuilder';

import kyInstance from '@wb/shared/client/kyInstance';

import {
  clearAccessToken,
  clearIdentityToken,
  saveAccessToken,
  saveIdentityToken,
} from '@wb/shared/utils/sessionStorage';

import { postPresignedUrl } from '@wb/shared/utils/fileUploads';

import reducer, { ReducerActionTypes, initialState } from './reducer';

export const DISCLOSURE_FORM_STEPS = [
  'identification',
  'subject',
  'case',
  'review',
];

export const DisclosureFormContext = createContext();

export const DisclosureFormProvider = (props) => {
  const { t } = useTranslation('shared-common');
  const [state, dispatch] = useReducer(reducer, initialState);

  const start = useCallback(() => {
    dispatch({
      type: ReducerActionTypes.START,
      payload: DISCLOSURE_FORM_STEPS[0],
    });
  }, []);

  const nextStep = useCallback(() => {
    if (
      !(
        DISCLOSURE_FORM_STEPS.findIndex((step) => step === state.currentStep) <
        DISCLOSURE_FORM_STEPS.length - 1
      )
    ) {
      return;
    }

    dispatch({
      type: ReducerActionTypes.NEXT_STEP,
      payload:
        DISCLOSURE_FORM_STEPS[
          DISCLOSURE_FORM_STEPS.findIndex(
            (step) => step === state.currentStep
          ) + 1
        ],
    });
  }, [state.currentStep]);

  const previousStep = useCallback(() => {
    if (
      !(
        DISCLOSURE_FORM_STEPS.findIndex((step) => step === state.currentStep) >
        0
      )
    ) {
      return;
    }

    dispatch({
      type: ReducerActionTypes.PREVIOUS_STEP,
      payload:
        DISCLOSURE_FORM_STEPS[
          DISCLOSURE_FORM_STEPS.findIndex(
            (step) => step === state.currentStep
          ) - 1
        ],
    });
  }, [state.currentStep]);

  const setCurrentStepValues = useCallback(
    (values) => {
      dispatch({
        type: ReducerActionTypes.SET_CURRENT_STEP_VALUES,
        payload: {
          step: state.currentStep,
          values,
        },
      });
    },
    [state.currentStep]
  );

  const addAttachment = useCallback((value) => {
    dispatch({
      type: ReducerActionTypes.ADD_ATTACHMENT,
      payload: value,
    });
  }, []);

  const editAttachment = useCallback((value) => {
    dispatch({
      type: ReducerActionTypes.EDIT_ATTACHMENT,
      payload: value,
    });
  }, []);

  const removeAttachment = useCallback((value) => {
    dispatch({
      type: ReducerActionTypes.REMOVE_ATTACHMENT,
      payload: value,
    });
  }, []);

  const resetDisclosureForm = useCallback(() => {
    dispatch({
      type: ReducerActionTypes.RESET_DISCLOSURE_FORM,
    });
  }, []);

  const submit = useCallback(async () => {
    let response;
    const { values, attachments, captcha } = state;
    const attachmentsData = [];
    let accessToken, idToken, token, presignedUrl;

    dispatch({
      type: ReducerActionTypes.SUBMITTING_DISCLOSURE_FORM,
    });

    try {
      ({ accessToken, idToken, token, presignedUrl } = await kyInstance
        .post(`generate-token`, {
          json: {
            files: attachments.map((attachment) => attachment.file.name),
            captcha,
          },
        })
        .json());
    } catch (err) {
      dispatch({
        type: ReducerActionTypes.SET_FORM_ERROR,
        payload: err,
      });
      return;
    }

    await Promise.all(
      attachments.map(async ({ file, description }) => {
        const pUrl = presignedUrl
          .filter((entry) => entry.fields.key.endsWith(file.name))
          .pop();

        const response = await postPresignedUrl(pUrl, file);
        if (response?.status === 204) {
          attachmentsData.push({
            description,
            name: file.name,
            url: pUrl.fields.key,
          });
        }
      })
    );

    saveAccessToken(accessToken);
    saveIdentityToken(idToken);

    try {
      response = await kyInstance.post('disclosures', {
        json: sanitizeSubmitData(values, attachmentsData),
      });
    } catch (err) {
      dispatch({
        type: ReducerActionTypes.SET_FORM_ERROR,
        payload: err,
      });
      return;
    }

    const { id } = await response.json();

    if (id) {
      clearAccessToken();
      clearIdentityToken();
      dispatch({
        type: ReducerActionTypes.SUBMIT_DISCLOSURE_FORM,
        payload: {
          tokenID: token,
        },
      });
    }
  }, [state]);

  const setConfirm = useCallback((confirm) => {
    dispatch({
      type: ReducerActionTypes.SET_CONFIRM,
      payload: {
        confirm,
      },
    });
  }, []);

  const setTermsOfUse = useCallback((termsOfUse) => {
    dispatch({
      type: ReducerActionTypes.SET_TERMS_OF_USE,
      payload: {
        termsOfUse,
      },
    });
  }, []);

  const setCaptcha = useCallback((captcha) => {
    dispatch({
      type: ReducerActionTypes.SET_CAPTCHA,
      payload: {
        captcha,
      },
    });
  }, []);

  const createFormSchemas = useCallback(
    (steps) => {
      return disclosureFormBuilder(steps, t);
    },
    [t]
  );

  const context = useMemo(
    () => ({
      ...state,
      actions: {
        start,
        nextStep,
        previousStep,
        setCurrentStepValues,
        addAttachment,
        editAttachment,
        removeAttachment,
        resetDisclosureForm,
        setConfirm,
        setTermsOfUse,
        submit,
        setCaptcha,
        createFormSchemas,
      },
    }),
    [
      state,
      start,
      nextStep,
      previousStep,
      setCurrentStepValues,
      addAttachment,
      editAttachment,
      removeAttachment,
      resetDisclosureForm,
      setConfirm,
      setTermsOfUse,
      submit,
      setCaptcha,
      createFormSchemas,
    ]
  );

  return <DisclosureFormContext.Provider value={context} {...props} />;
};

export const useDisclosureForm = () => {
  return useContext(DisclosureFormContext);
};

export default useDisclosureForm;
