export const initialState = {
  tokenID: null,
  values: {},
  currentStep: null,
  submitted: false,
  status: 'PENDING',
  attachments: [],
  confirm: false,
  termsOfUse: false,
  error: null,
  captcha: null,
};

export const ReducerActionTypes = {
  START: 'START_FORM',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  RESET_DISCLOSURE_FORM: 'RESET_DISCLOSURE_FORM',
  SET_CURRENT_STEP_VALUES: 'SET_CURRENT_STEP_VALUES',
  SUBMITTING_DISCLOSURE_FORM: 'SUBMITTING_DISCLOSURE_FORM',
  SUBMIT_DISCLOSURE_FORM: 'SUBMIT_FORM',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
  EDIT_ATTACHMENT: 'EDIT_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  SET_CONFIRM: 'SET_CONFIRM',
  SET_TERMS_OF_USE: 'SET_TERMS_OF_USE',
  SET_FORM_ERROR: 'SET_FORM_ERROR',
  SET_CAPTCHA: 'SET_CAPTCHA',
};

function reducer(state = initialState, action) {
  let attachments = [...state.attachments];
  let index;

  switch (action.type) {
    case ReducerActionTypes.START:
      return {
        ...initialState,
        currentStep: action.payload,
      };
    case ReducerActionTypes.NEXT_STEP:
    case ReducerActionTypes.PREVIOUS_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case ReducerActionTypes.SET_CURRENT_STEP_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.step]: {
            ...action.payload.values,
          },
        },
      };
    case ReducerActionTypes.ADD_ATTACHMENT:
      return {
        ...state,
        attachments: [
          ...state.attachments,
          { id: `file-${new Date().getTime()}`, ...action.payload },
        ],
      };
    case ReducerActionTypes.EDIT_ATTACHMENT:
      index = attachments.findIndex(({ id }) => id === action.payload.id);
      attachments[index] = action.payload;
      return {
        ...state,
        attachments,
      };
    case ReducerActionTypes.REMOVE_ATTACHMENT:
      index = attachments.findIndex(({ id }) => id === action.payload.id);
      attachments.splice(index, 1);
      return {
        ...state,
        attachments,
      };
    case ReducerActionTypes.RESET_DISCLOSURE_FORM:
      return {
        ...initialState,
      };
    case ReducerActionTypes.SUBMITTING_DISCLOSURE_FORM:
      return {
        ...state,
        status: 'SUBMITTING',
      };
    case ReducerActionTypes.SUBMIT_DISCLOSURE_FORM:
      return {
        ...state,
        attachments: [],
        submitted: true,
        status: 'RESOLVED',
        error: null,
        ...action.payload,
      };
    case ReducerActionTypes.SET_CONFIRM:
      return {
        ...state,
        confirm: action.payload.confirm,
      };
    case ReducerActionTypes.SET_TERMS_OF_USE:
      return {
        ...state,
        termsOfUse: action.payload.termsOfUse,
      };
    case ReducerActionTypes.SET_FORM_ERROR:
      return {
        ...state,
        status: 'PENDING',
        error: action.payload,
      };
    case ReducerActionTypes.SET_CAPTCHA:
      return {
        ...state,
        captcha: action.payload.captcha,
      };
    default:
      return state;
  }
}

export default reducer;
