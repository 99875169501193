import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const smallStyles = css`
  width: 0.8rem;
  height: 0.8rem;
`;

const mediumStyles = css`
  width: 1rem;
  height: 1rem;
`;

const largeStyles = css`
  width: 1.2rem;
  height: 1.2rem;
`;

const sizeStylesMapper = {
  small: smallStyles,
  medium: mediumStyles,
  large: largeStyles,
};

export const Div = styled.div`
  color: var(--palette-primary-color);
`;

export const DotSpan = styled.span`
  ${({ size }) => sizeStylesMapper[size]};

  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;

  &:nth-of-type(1) {
    animation-delay: -0.32s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.16s;
  }
`;
