import styled from '@emotion/styled';

import Heading from '@wb/shared/components/typography/Heading';

import typography from '@wb/shared/theme/typography/index';
import { weight } from '@wb/shared/theme/typography/fonts';

import Button from '../button/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 1rem;
  color: var(--palette-${({ color }) => color});
`;

export const StyledContainer = styled.p`
  ${typography.small};
  font-weight: ${weight.regular};
  color: var(--palette-${({ color }) => color});
  margin-top: 0.2rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 1.5rem;
`;
