import {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react';

import {
  saveAccessToken,
  saveIdentityToken,
} from '@wb/shared/utils/sessionStorage';

import kyInstance from '@wb/shared/client/kyInstance';

import reducer, { ReducerActionTypes, initialState } from './reducer';

export const DisclosureFollowContext = createContext();

export const DisclosureFollowProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setToken = useCallback(async (token) => {
    const { accessToken, idToken } = await kyInstance
      .post(`get-token`, { json: { token } })
      .json();

    if (accessToken) {
      saveAccessToken(accessToken);
      saveIdentityToken(idToken);

      dispatch({
        type: ReducerActionTypes.SET_TOKEN,
        payload: accessToken,
      });
    }
  }, []);

  const clearToken = useCallback(() => {
    dispatch({
      type: ReducerActionTypes.CLEAR_TOKEN,
    });
  }, []);

  const context = useMemo(
    () => ({
      ...state,
      setToken,
      clearToken,
    }),
    [state, setToken, clearToken]
  );

  return <DisclosureFollowContext.Provider value={context} {...props} />;
};

export const useDisclosureFollow = () => {
  return useContext(DisclosureFollowContext);
};

export default useDisclosureFollow;
