import React, { Suspense } from 'react';
import { Global } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CustomerProvider } from '@wb/shared/state/customer/useCustomer';

import useToggleKeyboardStyles from '@wb/shared/hooks/useToggleKeyboardStyles';

import PageLoader from '@wb/shared/components/page-loader/PageLoader';
import { GenericErrorBoundary } from '@wb/shared/components/error-boundary/ErrorBoundary';

import { ViewportProvider } from '@wb/shared/hooks/useViewport';

import globalStyles from '@wb/frontoffice/theme/global';

import { DisclosureFormProvider } from '@wb/frontoffice/state/disclosure-form/useDisclosureForm';
import { DisclosureFollowProvider } from '@wb/frontoffice/state/disclosure-follow/useDisclosureFollow';

import routes from './pages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // Stale data after 5min, the same value as the default `cacheTime` option
      staleTime: 300000,
    },
  },
});

const App = () => {
  const { globalStyles: toggledKeyboardGlobalStyles } =
    useToggleKeyboardStyles();

  return (
    <BrowserRouter>
      <GenericErrorBoundary>
        <ViewportProvider>
          <QueryClientProvider client={queryClient}>
            <Global styles={globalStyles} />
            <Suspense fallback={<PageLoader />}>
              <CustomerProvider>
                <DisclosureFollowProvider>
                  <DisclosureFormProvider>
                    {toggledKeyboardGlobalStyles}
                    {renderRoutes(routes)}
                  </DisclosureFormProvider>
                </DisclosureFollowProvider>
              </CustomerProvider>
            </Suspense>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ViewportProvider>
      </GenericErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
