import { css } from '@emotion/react';

export default css`
  --palette-grey-darkest: #000000;
  --palette-grey-darker: #404040;
  --palette-grey-dark: #808080;
  --palette-grey: #b5b5b5;
  --palette-grey-light: #d4d4d4;
  --palette-grey-lighter: #f5f6f6;

  --palette-success-dark: #36b972;
  --palette-success-main: #2ace75;
  --palette-success-light: #f0fff6;

  --palette-error-dark: #c93d31;
  --palette-error-main: #df2f1f;
  --palette-error-light: #fff1f1;

  --palette-action-disabled: #c2cfe0;

  --palette-white: #ffffff;
  --palette-black: #000000;

  --palette-shadow: #00000029;
  --palette-scrollbar-bg: ##f5f6f6d9;

  --palette-red: #df2f1f;
  --palette-red-light: #fb8074;
  --palette-red-lighter: #faf0f0;

  ${
    '' /* Customer colors started in grayscale and configured after the init request by the CustomerProvider component */
  }
  --palette-primary-color: var(--palette-grey-darker);
  --palette-secondary-color: var(--palette-grey-light);
`;
