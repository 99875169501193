export default {
  name: 'overload',
  lookup() {
    if (
      ['nb', 'nb-NO', 'nn', 'nn-NO'].some(
        (norwegian) => navigator.language === norwegian
      )
    )
      return 'no';
  },
};
