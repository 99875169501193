import { lazy } from 'react';

import * as urls from './urls';

const routes = [
  {
    path: urls.HOMEPAGE_URL,
    exact: true,
    component: lazy(() => import('./home/Home')),
  },
  {
    path: urls.DISCLOSURE_FORM_URL,
    exact: true,
    component: lazy(() => import('./disclosure/Disclosure')),
  },
  {
    path: urls.DISCLOSURE_FOLLOW_CASE_URL,
    exact: true,
    component: lazy(() => import('./follow-case/FollowCase')),
  },
  {
    path: urls.DISCLOSURE_DETAILS_URL,
    component: lazy(() => import('./disclosure-details/DisclosureDetails')),
  },
  {
    path: '*',
    component: lazy(() => import('@wb/shared/pages/not-found/NotFound')),
  },
];

export default routes;
