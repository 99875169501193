export const initialState = {
  tokenID: null,
};

export const ReducerActionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  CLEAR_TOKEN: 'CLEAR_TOKEN',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ReducerActionTypes.SET_TOKEN:
      return {
        ...initialState,
        token: action.payload,
      };
    case ReducerActionTypes.CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
}

export default reducer;
