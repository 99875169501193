import React from 'react';
const Oops = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="359.386"
    height="222.867"
    viewBox="0 0 359.386 222.867"
  >
    <g
      id="Group_9871"
      data-name="Group 9871"
      transform="translate(-332 -195.126)"
    >
      <g
        id="Group_2710"
        data-name="Group 2710"
        transform="translate(344.984 359.208)"
      >
        <g id="Group_2688" data-name="Group 2688">
          <g id="Group_2687" data-name="Group 2687">
            <path
              id="Path_6209"
              data-name="Path 6209"
              d="M71.539,373.535s-1.577,3.69,7.034,6.267A103.978,103.978,0,0,0,92,383.07s1.844.76.972,2.091-3.648,3.442-2.746,5.269c.937,1.894,3.287,2.145,6.5,1.165s10.315-2.867,10.958-3.035,1.642.029,1.193,1.031-5.216,7.163-2.157,9.25,11.547-2.11,12.484-2.551,1.392-.325,1.721.1-1.469,2.343-2.753,4.151-2.457,4.13-.591,5.186,8.665.262,18.412-8.125l4.263-4.182-1.4-5.832c-3.762-12.3-9.086-16.6-11.228-16.7s-2.391,2.485-2.27,4.7.614,4.8.111,4.979-.951.037-1.508-.836-5.492-8.955-9.193-8.823-2.961,7.886-3.116,8.973-1.113.712-1.559.234-5.455-5.877-7.643-8.418-4.311-3.584-6.114-2.483c-1.739,1.062-.519,4.33-.492,5.92s-1.938,1.248-1.938,1.248a103.95,103.95,0,0,0-13.1-4.409c-8.655-2.42-9.29,1.645-9.29,1.645"
              transform="translate(-71.426 -368.771)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2691"
          data-name="Group 2691"
          transform="translate(7.82 7.085)"
        >
          <g id="Group_2690" data-name="Group 2690">
            <g id="Group_2689" data-name="Group 2689">
              <path
                id="Path_6210"
                data-name="Path 6210"
                d="M148.084,400.142c-6.349-1.8-17.581-5.194-27.239-8.057s-18.4-5.444-24.738-7.278L88.6,382.642l-2.042-.59-.53-.158c-.119-.036-.178-.06-.178-.06s.063.011.184.041l.535.14,2.051.556c1.781.485,4.353,1.206,7.527,2.107,6.349,1.8,15.1,4.365,24.755,7.227s20.883,6.275,27.223,8.109"
                transform="translate(-85.846 -381.835)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2694"
          data-name="Group 2694"
          transform="translate(22.807 14.313)"
        >
          <g id="Group_2693" data-name="Group 2693">
            <g id="Group_2692" data-name="Group 2692">
              <path
                id="Path_6211"
                data-name="Path 6211"
                d="M113.482,399.712a1.921,1.921,0,0,1,.371-.221c.244-.132.6-.313,1.048-.533.89-.438,2.136-1.01,3.522-1.618l3.553-1.542,1.073-.474a1.961,1.961,0,0,1,.4-.159,1.912,1.912,0,0,1-.37.224c-.245.132-.6.313-1.05.528-.895.429-2.148.985-3.533,1.593l-3.541,1.567-1.071.479a1.938,1.938,0,0,1-.4.156Z"
                transform="translate(-113.482 -395.163)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2697"
          data-name="Group 2697"
          transform="translate(27.165 2.436)"
        >
          <g id="Group_2696" data-name="Group 2696">
            <g id="Group_2695" data-name="Group 2695">
              <path
                id="Path_6212"
                data-name="Path 6212"
                d="M127.134,384.583c-.046.023-1.34-2.493-2.891-5.618s-2.771-5.679-2.725-5.7,1.34,2.492,2.891,5.619S127.18,384.56,127.134,384.583Z"
                transform="translate(-121.518 -373.263)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2700"
          data-name="Group 2700"
          transform="translate(44.991 7.908)"
        >
          <g id="Group_2699" data-name="Group 2699">
            <g id="Group_2698" data-name="Group 2698">
              <path
                id="Path_6213"
                data-name="Path 6213"
                d="M156.808,394.265a2.047,2.047,0,0,1-.1-.43c-.057-.316-.127-.711-.212-1.18-.18-1-.433-2.369-.769-3.875s-.681-2.86-.941-3.837c-.121-.46-.225-.848-.307-1.158a2.031,2.031,0,0,1-.092-.431,1.98,1.98,0,0,1,.162.411c.092.268.219.658.364,1.144.293.97.662,2.322,1,3.833s.571,2.892.717,3.894c.072.5.125.908.153,1.19a1.991,1.991,0,0,1,.025.439Z"
                transform="translate(-154.387 -383.354)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2703"
          data-name="Group 2703"
          transform="translate(39.406 18.743)"
        >
          <g id="Group_2702" data-name="Group 2702">
            <g id="Group_2701" data-name="Group 2701">
              <path
                id="Path_6214"
                data-name="Path 6214"
                d="M144.09,410.012a1.731,1.731,0,0,1,.287-.29l.826-.738c.7-.617,1.682-1.46,2.771-2.382s2.082-1.75,2.808-2.341l.864-.693a1.728,1.728,0,0,1,.333-.235,1.762,1.762,0,0,1-.286.29l-.826.738c-.7.617-1.683,1.46-2.772,2.382s-2.082,1.749-2.806,2.341l-.864.693A1.7,1.7,0,0,1,144.09,410.012Z"
                transform="translate(-144.089 -403.333)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2706"
          data-name="Group 2706"
          transform="translate(51.755 22.877)"
        >
          <g id="Group_2705" data-name="Group 2705">
            <g id="Group_2704" data-name="Group 2704">
              <path
                id="Path_6215"
                data-name="Path 6215"
                d="M166.86,419.613a2.765,2.765,0,0,1,.37-.368l1.055-.95c.9-.8,2.144-1.886,3.528-3.081s2.645-2.27,3.564-3.04l1.094-.9a2.925,2.925,0,0,1,.418-.313,2.736,2.736,0,0,1-.37.368l-1.055.95c-.9.8-2.144,1.886-3.529,3.081s-2.645,2.27-3.564,3.04l-1.094.9a2.843,2.843,0,0,1-.417.313Z"
                transform="translate(-166.859 -410.955)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2709"
          data-name="Group 2709"
          transform="translate(58.039 9.259)"
        >
          <g id="Group_2708" data-name="Group 2708">
            <g id="Group_2707" data-name="Group 2707">
              <path
                id="Path_6216"
                data-name="Path 6216"
                d="M181.9,399.6a3.123,3.123,0,0,1-.139-.541c-.083-.383-.191-.883-.322-1.486-.273-1.254-.657-2.985-1.134-4.884s-.95-3.608-1.3-4.842l-.416-1.462a3.27,3.27,0,0,1-.133-.543,3.172,3.172,0,0,1,.2.521c.118.339.281.833.473,1.446.385,1.226.881,2.932,1.356,4.835s.842,3.641,1.082,4.9c.118.632.209,1.144.264,1.5A3.243,3.243,0,0,1,181.9,399.6Z"
                transform="translate(-178.448 -385.844)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2746"
        data-name="Group 2746"
        transform="translate(580.565 216.771)"
      >
        <g id="Group_2715" data-name="Group 2715">
          <g id="Group_2714" data-name="Group 2714">
            <g id="Group_2713" data-name="Group 2713">
              <g id="Group_2712" data-name="Group 2712">
                <g id="Group_2711" data-name="Group 2711">
                  <path
                    id="Path_6217"
                    data-name="Path 6217"
                    d="M515.13,158.186c-7.571-5.092-9.439-14.76-9.3-23.483.023-1.456-.164-2.987.506-4.276a2.963,2.963,0,0,1,3.583-1.6c1.09.49,1.582,1.738,2.206,2.767a8.509,8.509,0,0,0,3.47,3.2,3.242,3.242,0,0,0,2.992.217c1.243-.715,1.36-2.451,1.326-3.9q-.1-4.045-.194-8.091a13.491,13.491,0,0,1,.361-4.306,4.489,4.489,0,0,1,2.768-3.085,2.723,2.723,0,0,1,3.4,1.765,13.431,13.431,0,0,1,.073,1.8,1.523,1.523,0,0,0,.916,1.417,1.75,1.75,0,0,0,1.578-.692,19.242,19.242,0,0,0,3.362-5.277A26.718,26.718,0,0,1,535.3,109.2a7.828,7.828,0,0,1,5.25-3.068,4.687,4.687,0,0,1,4.862,3.111c.561,2.032-.593,4.123-1.859,5.8a32.3,32.3,0,0,1-6.613,6.531,2.661,2.661,0,0,0-1.1,1.23c-.3.97.634,1.912,1.592,2.213a16.755,16.755,0,0,0,3.385.321,3.255,3.255,0,0,1,2.808,1.648c.619,1.469-.591,3.041-1.79,4.078a27.692,27.692,0,0,1-8.107,4.873,9.328,9.328,0,0,0-3.017,1.533,2.678,2.678,0,0,0-.8,3.083,2.728,2.728,0,0,0,2.985,1.087,19.285,19.285,0,0,0,3.155-1.27c1.975-.786,4.546-.694,5.851,1a4.58,4.58,0,0,1,.559,4.041,10.554,10.554,0,0,1-2.131,3.616,27.833,27.833,0,0,1-11.734,8.561c-4.585,1.681-8.853,2.19-13.467.594"
                    transform="translate(-505.825 -106.124)"
                    fill="#ebebeb"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2721"
          data-name="Group 2721"
          transform="translate(11.458 1.176)"
        >
          <g id="Group_2720" data-name="Group 2720">
            <g id="Group_2719" data-name="Group 2719">
              <g id="Group_2718" data-name="Group 2718">
                <g id="Group_2717" data-name="Group 2717">
                  <g id="Group_2716" data-name="Group 2716">
                    <path
                      id="Path_6218"
                      data-name="Path 6218"
                      d="M526.954,153.674a129.653,129.653,0,0,1,4.9-14.053L533.328,136c.486-1.186.969-2.363,1.511-3.484a64.681,64.681,0,0,1,3.489-6.265c1.206-1.939,2.421-3.735,3.569-5.407s2.256-3.206,3.322-4.563a51.338,51.338,0,0,1,5.5-6.022c.7-.658,1.264-1.15,1.649-1.479l.44-.371a1.128,1.128,0,0,1,.156-.119,1.011,1.011,0,0,1-.138.14l-.423.39c-.373.342-.923.846-1.614,1.515a54.317,54.317,0,0,0-5.423,6.064c-1.053,1.361-2.145,2.9-3.287,4.572s-2.347,3.474-3.544,5.412a65.267,65.267,0,0,0-3.461,6.249c-.539,1.116-1.015,2.283-1.5,3.472L532.1,139.72a133.069,133.069,0,0,0-4.911,14.018"
                      transform="translate(-526.954 -108.293)"
                      fill="#e0e0e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2727"
          data-name="Group 2727"
          transform="translate(17.02 9.506)"
        >
          <g id="Group_2726" data-name="Group 2726">
            <g id="Group_2725" data-name="Group 2725">
              <g id="Group_2724" data-name="Group 2724">
                <g id="Group_2723" data-name="Group 2723">
                  <g id="Group_2722" data-name="Group 2722">
                    <path
                      id="Path_6219"
                      data-name="Path 6219"
                      d="M539.61,139.538a2.944,2.944,0,0,1-.152-.615c-.083-.4-.184-.979-.3-1.7-.23-1.437-.48-3.433-.747-5.635s-.545-4.189-.8-5.619c-.124-.715-.229-1.293-.308-1.691a2.9,2.9,0,0,1-.092-.627,2.893,2.893,0,0,1,.193.6c.109.392.244.966.392,1.68.3,1.426.608,3.417.876,5.621.259,2.173.492,4.139.67,5.641.085.678.157,1.249.215,1.708a2.912,2.912,0,0,1,.053.637Z"
                      transform="translate(-537.21 -123.653)"
                      fill="#e0e0e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2733"
          data-name="Group 2733"
          transform="translate(19.42 21.57)"
        >
          <g id="Group_2732" data-name="Group 2732">
            <g id="Group_2731" data-name="Group 2731">
              <g id="Group_2730" data-name="Group 2730">
                <g id="Group_2729" data-name="Group 2729">
                  <g id="Group_2728" data-name="Group 2728">
                    <path
                      id="Path_6220"
                      data-name="Path 6220"
                      d="M560.18,145.912a4.049,4.049,0,0,1-.741.1c-.477.051-1.167.121-2.016.235-1.7.214-4.04.592-6.6,1.122s-4.861,1.118-6.511,1.587c-.827.229-1.491.432-1.951.569a3.987,3.987,0,0,1-.723.191,3.955,3.955,0,0,1,.69-.291c.451-.169,1.109-.4,1.931-.653,1.641-.517,3.94-1.131,6.51-1.668a65.711,65.711,0,0,1,6.635-1.047c.855-.09,1.55-.131,2.03-.15A3.926,3.926,0,0,1,560.18,145.912Z"
                      transform="translate(-541.635 -145.898)"
                      fill="#e0e0e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2739"
          data-name="Group 2739"
          transform="translate(1.89 22.612)"
        >
          <g id="Group_2738" data-name="Group 2738">
            <g id="Group_2737" data-name="Group 2737">
              <g id="Group_2736" data-name="Group 2736">
                <g id="Group_2735" data-name="Group 2735">
                  <g id="Group_2734" data-name="Group 2734">
                    <path
                      id="Path_6221"
                      data-name="Path 6221"
                      d="M519.1,171.419a.962.962,0,0,1-.131-.225l-.33-.668c-.284-.581-.673-1.432-1.14-2.49-.936-2.116-2.136-5.079-3.416-8.366s-2.452-6.259-3.342-8.392l-1.061-2.522c-.114-.275-.208-.5-.285-.688a.948.948,0,0,1-.083-.248.987.987,0,0,1,.132.225l.33.667c.284.581.674,1.432,1.141,2.49.935,2.116,2.136,5.079,3.416,8.368s2.452,6.258,3.342,8.391c.437,1.036.794,1.889,1.061,2.522l.285.688a.949.949,0,0,1,.081.248Z"
                      transform="translate(-509.311 -147.82)"
                      fill="#e0e0e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2745"
          data-name="Group 2745"
          transform="translate(11.681 37.802)"
        >
          <g id="Group_2744" data-name="Group 2744">
            <g id="Group_2743" data-name="Group 2743">
              <g id="Group_2742" data-name="Group 2742">
                <g id="Group_2741" data-name="Group 2741">
                  <g id="Group_2740" data-name="Group 2740">
                    <path
                      id="Path_6222"
                      data-name="Path 6222"
                      d="M551.949,175.832a1.022,1.022,0,0,1-.243.1l-.714.253-2.633.9c-2.222.765-5.281,1.856-8.658,3.066s-6.449,2.275-8.688,2.994c-1.119.36-2.028.642-2.66.823l-.729.206a.993.993,0,0,1-.26.054,1.032,1.032,0,0,1,.243-.106l.714-.253,2.632-.9c2.223-.765,5.281-1.856,8.659-3.067s6.448-2.275,8.687-2.994c1.119-.36,2.028-.642,2.661-.824l.729-.205a.932.932,0,0,1,.26-.057Z"
                      transform="translate(-527.364 -175.83)"
                      fill="#e0e0e0"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2762"
        data-name="Group 2762"
        transform="translate(645.891 300.13)"
      >
        <g
          id="Group_2748"
          data-name="Group 2748"
          transform="translate(12.169 3.427)"
        >
          <g id="Group_2747" data-name="Group 2747">
            <path
              id="Path_6223"
              data-name="Path 6223"
              d="M663.466,267.82a5.5,5.5,0,0,1-2.025,3.8,9.7,9.7,0,0,1-3.964,1.888,17.139,17.139,0,0,1-8.755-.643c1.116-2.97,4.332-4.762,6.02-5.568,2.669-1.274,8.266-1.957,8.724.52"
              transform="translate(-648.722 -266.152)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2750"
          data-name="Group 2750"
          transform="translate(8.755 13.681)"
        >
          <g id="Group_2749" data-name="Group 2749">
            <path
              id="Path_6224"
              data-name="Path 6224"
              d="M642.427,285.767a4.693,4.693,0,0,1,4.711-.144,6.233,6.233,0,0,1,2.957,3.8,2.628,2.628,0,0,1-.141,2.208,2.009,2.009,0,0,1-1.9.742,4.073,4.073,0,0,1-1.917-.89c-1.944-1.484-3.552-3.278-3.715-5.718"
              transform="translate(-642.427 -285.06)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2753"
          data-name="Group 2753"
          transform="translate(1.613 13.628)"
        >
          <g id="Group_2752" data-name="Group 2752">
            <g id="Group_2751" data-name="Group 2751">
              <path
                id="Path_6225"
                data-name="Path 6225"
                d="M629.257,285.375a9.243,9.243,0,0,1,1.856-.35,8.76,8.76,0,0,1,2.065,0,19.21,19.21,0,0,1,2.49.384,9.387,9.387,0,0,1,4.078,2.054,6.786,6.786,0,0,1,.93,1.036,1.5,1.5,0,0,1,.252.444c-.047.035-.444-.575-1.33-1.309a10.07,10.07,0,0,0-4.005-1.914,22.509,22.509,0,0,0-2.446-.4,9.661,9.661,0,0,0-2.011-.064,13.489,13.489,0,0,1-1.879.119Z"
                transform="translate(-629.257 -284.962)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2756"
          data-name="Group 2756"
          transform="translate(0 5.988)"
        >
          <g id="Group_2755" data-name="Group 2755">
            <g id="Group_2754" data-name="Group 2754">
              <path
                id="Path_6226"
                data-name="Path 6226"
                d="M648.98,270.877a5.386,5.386,0,0,1-.857.422c-.56.253-1.375.608-2.386,1.037-2.022.858-4.829,2.014-7.96,3.214s-6,2.2-8.086,2.888c-1.044.342-1.892.609-2.482.781a5.462,5.462,0,0,1-.925.239,5.5,5.5,0,0,1,.886-.357l2.448-.877c2.067-.738,4.917-1.776,8.045-2.974l7.989-3.124,2.424-.943A5.566,5.566,0,0,1,648.98,270.877Z"
                transform="translate(-626.283 -270.875)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g id="Group_2758" data-name="Group 2758" transform="translate(5.129)">
          <g id="Group_2757" data-name="Group 2757">
            <path
              id="Path_6227"
              data-name="Path 6227"
              d="M637.08,271.224a10.98,10.98,0,0,0,4.22-9.1c-.043-.885-.32-1.921-1.155-2.216s-1.706.343-2.28,1.019a9.381,9.381,0,0,0-1.017,10.36"
              transform="translate(-635.741 -259.833)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2761"
          data-name="Group 2761"
          transform="translate(2.221 3.992)"
        >
          <g id="Group_2760" data-name="Group 2760">
            <g id="Group_2759" data-name="Group 2759">
              <path
                id="Path_6228"
                data-name="Path 6228"
                d="M636.888,267.194a1.543,1.543,0,0,1,.01.5c-.01.323-.055.787-.127,1.361a12.39,12.39,0,0,1-1.339,4.313,7.772,7.772,0,0,1-1.567,1.96,7.561,7.561,0,0,1-2.993,1.61,1.5,1.5,0,0,1-.493.1c-.02-.061.672-.25,1.676-.78a8.121,8.121,0,0,0,1.612-1.146,8.006,8.006,0,0,0,1.486-1.9,13.958,13.958,0,0,0,1.394-4.192A15.78,15.78,0,0,1,636.888,267.194Z"
                transform="translate(-630.378 -267.194)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2778"
        data-name="Group 2778"
        transform="translate(362.022 211.987)"
      >
        <g id="Group_2764" data-name="Group 2764" transform="translate(1.468)">
          <g id="Group_2763" data-name="Group 2763">
            <path
              id="Path_6229"
              data-name="Path 6229"
              d="M107.026,97.326a6.508,6.508,0,0,1,4.8,1.722,11.493,11.493,0,0,1,2.887,4.32c1.285,3.126,1.247,7.018.74,10.36-3.668-.8-6.314-4.26-7.546-6.1-1.947-2.907-3.7-9.345-.879-10.3"
              transform="translate(-105.55 -97.303)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2766"
          data-name="Group 2766"
          transform="translate(15.62 9.679)"
        >
          <g id="Group_2765" data-name="Group 2765">
            <path
              id="Path_6230"
              data-name="Path 6230"
              d="M132.946,125.17a5.553,5.553,0,0,1-.972-5.491,7.374,7.374,0,0,1,3.948-4.11,3.1,3.1,0,0,1,2.609-.211,2.374,2.374,0,0,1,1.192,2.091,4.816,4.816,0,0,1-.715,2.4c-1.407,2.529-3.232,4.718-6.061,5.324"
              transform="translate(-131.645 -115.15)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2769"
          data-name="Group 2769"
          transform="translate(16.611 13.847)"
        >
          <g id="Group_2768" data-name="Group 2768">
            <g id="Group_2767" data-name="Group 2767">
              <path
                id="Path_6231"
                data-name="Path 6231"
                d="M134.594,137.11a10.9,10.9,0,0,1-.727-2.114,10.379,10.379,0,0,1-.357-2.417,22.775,22.775,0,0,1,.025-2.981,11.111,11.111,0,0,1,1.709-5.124,8.08,8.08,0,0,1,1.055-1.266c.29-.261.46-.389.477-.371.049.049-.6.618-1.306,1.78a11.915,11.915,0,0,0-1.558,5.016,26.73,26.73,0,0,0-.054,2.933,11.45,11.45,0,0,0,.268,2.366,16.125,16.125,0,0,1,.468,2.178Z"
                transform="translate(-133.473 -122.836)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2772"
          data-name="Group 2772"
          transform="translate(4.71 4.806)"
        >
          <g id="Group_2771" data-name="Group 2771">
            <g id="Group_2770" data-name="Group 2770">
              <path
                id="Path_6232"
                data-name="Path 6232"
                d="M111.53,106.166a6.478,6.478,0,0,1,.64.932c.392.612.946,1.506,1.621,2.617,1.35,2.221,3.181,5.311,5.119,8.772s3.6,6.647,4.76,8.976c.579,1.164,1.036,2.112,1.338,2.773a6.457,6.457,0,0,1,.437,1.043,6.507,6.507,0,0,1-.569-.977l-1.444-2.716c-1.216-2.295-2.918-5.454-4.853-8.913l-5.019-8.821-1.517-2.678A6.389,6.389,0,0,1,111.53,106.166Z"
                transform="translate(-111.529 -106.165)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2774"
          data-name="Group 2774"
          transform="translate(0 19.27)"
        >
          <g id="Group_2773" data-name="Group 2773">
            <path
              id="Path_6233"
              data-name="Path 6233"
              d="M116.646,136.454a12.99,12.99,0,0,0-11.376-3.389c-1.028.2-2.195.7-2.4,1.731s.692,1.939,1.582,2.5a11.1,11.1,0,0,0,12.3-.576"
              transform="translate(-102.843 -132.836)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2777"
          data-name="Group 2777"
          transform="translate(4.753 21.319)"
        >
          <g id="Group_2776" data-name="Group 2776">
            <g id="Group_2775" data-name="Group 2775">
              <path
                id="Path_6234"
                data-name="Path 6234"
                d="M111.607,136.8a1.8,1.8,0,0,1,.587-.1c.38-.043.932-.07,1.615-.084a14.669,14.669,0,0,1,5.278.832,9.2,9.2,0,0,1,2.562,1.5,8.952,8.952,0,0,1,2.395,3.23,1.793,1.793,0,0,1,.2.561c-.068.034-.408-.745-1.2-1.83a9.6,9.6,0,0,0-1.617-1.693,9.437,9.437,0,0,0-2.477-1.415,16.476,16.476,0,0,0-5.145-.918A18.819,18.819,0,0,1,111.607,136.8Z"
                transform="translate(-111.607 -136.615)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2794"
        data-name="Group 2794"
        transform="translate(493.174 389.639)"
      >
        <g
          id="Group_2780"
          data-name="Group 2780"
          transform="translate(0 10.115)"
        >
          <g id="Group_2779" data-name="Group 2779">
            <path
              id="Path_6235"
              data-name="Path 6235"
              d="M345.133,455.489a5.8,5.8,0,0,1,.024-4.546,10.238,10.238,0,0,1,2.775-3.711,18.083,18.083,0,0,1,8.493-3.7c.417,3.322-1.707,6.575-2.888,8.157-1.867,2.5-6.759,5.887-8.4,3.8"
              transform="translate(-344.68 -443.533)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2782"
          data-name="Group 2782"
          transform="translate(2.925 0.864)"
        >
          <g id="Group_2781" data-name="Group 2781">
            <path
              id="Path_6236"
              data-name="Path 6236"
              d="M360,430.084a4.952,4.952,0,0,1-4.329,2.448,6.577,6.577,0,0,1-4.629-2.1,2.769,2.769,0,0,1-.952-2.131,2.117,2.117,0,0,1,1.4-1.624,4.288,4.288,0,0,1,2.228-.11c2.544.431,4.927,1.316,6.277,3.515"
              transform="translate(-350.073 -426.475)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2785"
          data-name="Group 2785"
          transform="translate(7.038 1.287)"
        >
          <g id="Group_2784" data-name="Group 2784">
            <g id="Group_2783" data-name="Group 2783">
              <path
                id="Path_6237"
                data-name="Path 6237"
                d="M370.307,427.257a9.736,9.736,0,0,1-1.561,1.239,9.239,9.239,0,0,1-1.926,1.017,20.181,20.181,0,0,1-2.514.864,9.913,9.913,0,0,1-4.817.085,7.187,7.187,0,0,1-1.378-.511,1.7,1.7,0,0,1-.453-.291c.027-.055.7.319,1.885.569a10.628,10.628,0,0,0,4.681-.179,23.8,23.8,0,0,0,2.482-.826,10.184,10.184,0,0,0,1.91-.928,14.373,14.373,0,0,1,1.691-1.039Z"
                transform="translate(-357.657 -427.254)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g id="Group_2788" data-name="Group 2788" transform="translate(3.949)">
          <g id="Group_2787" data-name="Group 2787">
            <g id="Group_2786" data-name="Group 2786">
              <path
                id="Path_6238"
                data-name="Path 6238"
                d="M351.963,444.045a5.757,5.757,0,0,1,.593-.816c.4-.511.986-1.243,1.72-2.141,1.466-1.8,3.521-4.252,5.855-6.91s4.519-5.005,6.134-6.669c.807-.832,1.468-1.5,1.934-1.949a5.792,5.792,0,0,1,.747-.678,5.717,5.717,0,0,1-.652.769l-1.855,2.022c-1.568,1.7-3.719,4.073-6.053,6.728l-5.926,6.841-1.8,2.071a5.715,5.715,0,0,1-.7.732Z"
                transform="translate(-351.962 -424.882)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2790"
          data-name="Group 2790"
          transform="translate(15.426 5.977)"
        >
          <g id="Group_2789" data-name="Group 2789">
            <path
              id="Path_6239"
              data-name="Path 6239"
              d="M374.153,436.074a11.587,11.587,0,0,0,.529,10.572c.475.8,1.242,1.637,2.167,1.5s1.425-1.158,1.629-2.072A9.9,9.9,0,0,0,374.34,435.9"
              transform="translate(-373.125 -435.903)"
              fill="#ebebeb"
            />
          </g>
        </g>
        <g
          id="Group_2793"
          data-name="Group 2793"
          transform="translate(16.299 1.777)"
        >
          <g id="Group_2792" data-name="Group 2792">
            <g id="Group_2791" data-name="Group 2791">
              <path
                id="Path_6240"
                data-name="Path 6240"
                d="M376.412,440.552a1.617,1.617,0,0,1-.257-.465c-.149-.306-.335-.763-.549-1.334a13.06,13.06,0,0,1-.868-4.685,8.209,8.209,0,0,1,.5-2.6,7.985,7.985,0,0,1,2-2.973c.251-.232.4-.351.413-.335.048.047-.5.565-1.182,1.552a8.569,8.569,0,0,0-.943,1.863,8.441,8.441,0,0,0-.455,2.5,14.687,14.687,0,0,0,.757,4.6A16.9,16.9,0,0,1,376.412,440.552Z"
                transform="translate(-374.735 -428.159)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2798"
        data-name="Group 2798"
        transform="translate(332 248.105)"
      >
        <g id="Group_2795" data-name="Group 2795">
          <path
            id="Path_6241"
            data-name="Path 6241"
            d="M48.059,206.342c-2.283-13.175,2.295-27.734,12.814-35.988s26.86-8.868,37.048-.208c7.164,6.089,10.519,15.384,14.784,23.763s10.735,16.862,20.025,18.307c8.014,1.247,16.073-3.136,24.115-2.084,7.851,1.027,14.427,7.448,16.685,15.037s.572,16.015-3.481,22.817-10.286,12.1-17.111,16.114c-21.957,12.914-51.1,12.493-72.67-1.048s-34.623-39.6-32.541-64.987"
            transform="translate(-47.485 -163.903)"
            fill="#deefed"
          />
        </g>
        <g id="Group_2797" data-name="Group 2797" opacity="0.7">
          <g id="Group_2796" data-name="Group 2796">
            <path
              id="Path_6242"
              data-name="Path 6242"
              d="M48.059,206.342c-2.283-13.175,2.295-27.734,12.814-35.988s26.86-8.868,37.048-.208c7.164,6.089,10.519,15.384,14.784,23.763s10.735,16.862,20.025,18.307c8.014,1.247,16.073-3.136,24.115-2.084,7.851,1.027,14.427,7.448,16.685,15.037s.572,16.015-3.481,22.817-10.286,12.1-17.111,16.114c-21.957,12.914-51.1,12.493-72.67-1.048s-34.623-39.6-32.541-64.987"
              transform="translate(-47.485 -163.903)"
              fill="#f5f6f6"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_2802"
        data-name="Group 2802"
        transform="translate(424.064 372.094)"
      >
        <g id="Group_2799" data-name="Group 2799">
          <path
            id="Path_6243"
            data-name="Path 6243"
            d="M252.968,428.939c7.128-4.867,10.482-15.6,6.024-22.99h0c-4.514-6.728-11.52-12.091-19.543-13.221s-16.888,2.654-20.461,9.926a18.657,18.657,0,0,0-.389,14.857,23.61,23.61,0,0,0,9.863,11.338C235.851,433.308,245.84,433.806,252.968,428.939Z"
            transform="translate(-217.247 -392.53)"
            fill="#f5f6f6"
          />
        </g>
        <g id="Group_2801" data-name="Group 2801" opacity="0.7">
          <g id="Group_2800" data-name="Group 2800">
            <path
              id="Path_6244"
              data-name="Path 6244"
              d="M252.968,428.939c7.128-4.867,10.482-15.6,6.024-22.99h0c-4.514-6.728-11.52-12.091-19.543-13.221s-16.888,2.654-20.461,9.926a18.657,18.657,0,0,0-.389,14.857,23.61,23.61,0,0,0,9.863,11.338C235.851,433.308,245.84,433.806,252.968,428.939Z"
              transform="translate(-217.247 -392.53)"
              fill="#f5f6f6"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_2807"
        data-name="Group 2807"
        transform="translate(578.649 329.134)"
      >
        <g id="Group_2804" data-name="Group 2804">
          <g id="Group_2803" data-name="Group 2803">
            <path
              id="Path_6245"
              data-name="Path 6245"
              d="M615,347.76c-.443-11.414-6.1-22.734-15.564-29.125s-22.654-7.261-32.412-1.323c-6.077,3.7-10.489,9.591-14.16,15.685-2.011,3.339-4.112,7.022-7.725,8.483-4.7,1.9-9.871-.691-14.844-1.684a23.35,23.35,0,0,0-27.126,16.78c-4.347,18,8.278,30.851,21.772,38.648,15.084,8.717,34.143,8.709,50.564,2.892,10.767-3.814,20.8-10.086,28.133-18.845S615.441,359.174,615,347.76Z"
              transform="translate(-502.293 -313.314)"
              fill="#f5f6f6"
            />
          </g>
        </g>
        <g id="Group_2806" data-name="Group 2806" opacity="0.7">
          <g id="Group_2805" data-name="Group 2805">
            <path
              id="Path_6246"
              data-name="Path 6246"
              d="M615,347.76c-.443-11.414-6.1-22.734-15.564-29.125s-22.654-7.261-32.412-1.323c-6.077,3.7-10.489,9.591-14.16,15.685-2.011,3.339-4.112,7.022-7.725,8.483-4.7,1.9-9.871-.691-14.844-1.684a23.35,23.35,0,0,0-27.126,16.78c-4.347,18,8.278,30.851,21.772,38.648,15.084,8.717,34.143,8.709,50.564,2.892,10.767-3.814,20.8-10.086,28.133-18.845S615.441,359.174,615,347.76Z"
              transform="translate(-502.293 -313.314)"
              fill="#f5f6f6"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_2811"
        data-name="Group 2811"
        transform="translate(620.568 249.581)"
      >
        <g id="Group_2808" data-name="Group 2808">
          <path
            id="Path_6247"
            data-name="Path 6247"
            d="M624.452,183.108c-1.394-9.111-10.179-17.3-19.355-16.419h0c-8.563,1.239-16.963,5.508-21.756,12.711s-5.245,17.487.107,24.286a19.927,19.927,0,0,0,14.126,7.237,25.215,25.215,0,0,0,15.472-4.26C620.754,201.607,625.846,192.219,624.452,183.108Z"
            transform="translate(-579.589 -166.624)"
            fill="#f5f6f6"
          />
        </g>
        <g id="Group_2810" data-name="Group 2810" opacity="0.7">
          <g id="Group_2809" data-name="Group 2809">
            <path
              id="Path_6248"
              data-name="Path 6248"
              d="M624.452,183.108c-1.394-9.111-10.179-17.3-19.355-16.419h0c-8.563,1.239-16.963,5.508-21.756,12.711s-5.245,17.487.107,24.286a19.927,19.927,0,0,0,14.126,7.237,25.215,25.215,0,0,0,15.472-4.26C620.754,201.607,625.846,192.219,624.452,183.108Z"
              transform="translate(-579.589 -166.624)"
              fill="#f5f6f6"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3148"
        data-name="Group 3148"
        transform="translate(541.172 313.211)"
      >
        <g id="Group_3147" data-name="Group 3147">
          <g id="Group_3138" data-name="Group 3138">
            <g id="Group_3137" data-name="Group 3137">
              <path
                id="Path_6315"
                data-name="Path 6315"
                d="M532.128,333.531a11.1,11.1,0,0,1-3.2-.463c-10.307-3.086-9.771-10.52-9.341-16.495.4-5.572.415-9.075-4.18-10.8-6.076-2.28-8.58-.067-12.367,3.284-4.511,3.991-10.123,8.958-23.734,6.943-7.072-1.047-18.43-4.774-32.379-16.583a111.078,111.078,0,0,1-13.74-13.777l2.107-1.689c.208.259,21.028,25.916,44.406,29.377,12.365,1.832,17.245-2.485,21.549-6.294,3.861-3.416,7.507-6.641,15.106-3.791,6.718,2.521,6.3,8.366,5.925,13.522-.434,6.036-.81,11.248,7.422,13.712,9.275,2.779,21.472-11.915,28.762-20.693a57.132,57.132,0,0,1,5.852-6.572l2.04,1.619c-.9,1.105-3.407,3.779-5.814,6.678C553.117,320.455,542.258,333.53,532.128,333.531Z"
                transform="translate(-433.187 -283.953)"
                fill="#58595b"
              />
            </g>
          </g>
          <g
            id="Group_3140"
            data-name="Group 3140"
            transform="translate(50.959 28.196)"
          >
            <g id="Group_3139" data-name="Group 3139">
              <path
                id="Path_6316"
                data-name="Path 6316"
                d="M536.907,335.948c.009.016-.1.1-.322.241a9.629,9.629,0,0,1-.953.534,14.242,14.242,0,0,1-7,1.446,9.478,9.478,0,0,1-1.087-.112,1.192,1.192,0,0,1-.391-.093c.005-.044.571.013,1.483.017a16.91,16.91,0,0,0,6.916-1.428C536.388,336.186,536.884,335.91,536.907,335.948Z"
                transform="translate(-527.153 -335.945)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3142"
            data-name="Group 3142"
            transform="translate(71.106 19.799)"
          >
            <g id="Group_3141" data-name="Group 3141">
              <path
                id="Path_6317"
                data-name="Path 6317"
                d="M569.768,320.7a6.08,6.08,0,0,1-.886-.029,6.031,6.031,0,0,0-3.9,1.343c-.42.341-.645.6-.679.569a2.011,2.011,0,0,1,.555-.71,5.214,5.214,0,0,1,4.032-1.389A2.017,2.017,0,0,1,569.768,320.7Z"
                transform="translate(-564.303 -320.462)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3144"
            data-name="Group 3144"
            transform="translate(88.24 39.601)"
          >
            <g id="Group_3143" data-name="Group 3143">
              <path
                id="Path_6318"
                data-name="Path 6318"
                d="M601.5,363.03a3.588,3.588,0,0,1-1.219-.376,7.84,7.84,0,0,1-4.1-4.435,3.577,3.577,0,0,1-.279-1.244c.045-.01.154.463.454,1.175a8.734,8.734,0,0,0,4.008,4.334C601.051,362.84,601.515,362.986,601.5,363.03Z"
                transform="translate(-595.896 -356.976)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3146"
            data-name="Group 3146"
            transform="translate(127.332 20.237)"
          >
            <g id="Group_3145" data-name="Group 3145">
              <path
                id="Path_6319"
                data-name="Path 6319"
                d="M672.255,321.27a34.3,34.3,0,0,1-4.272,4.264A34.1,34.1,0,0,1,672.255,321.27Z"
                transform="translate(-667.981 -321.268)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_3173"
        data-name="Group 3173"
        transform="translate(502.904 279.843)"
      >
        <g id="Group_3168" data-name="Group 3168">
          <g
            id="Group_3152"
            data-name="Group 3152"
            transform="translate(31.314 26.743)"
          >
            <g
              id="Group_3149"
              data-name="Group 3149"
              transform="translate(0.184 0.184)"
            >
              <path
                id="Path_6320"
                data-name="Path 6320"
                d="M429.867,280.8h0a3.117,3.117,0,0,1-4.4.21l-4.758-4.324,4.194-4.615,4.758,4.324a3.117,3.117,0,0,1,.206,4.405Z"
                transform="translate(-420.705 -272.077)"
                fill="#58595b"
              />
            </g>
            <g id="Group_3151" data-name="Group 3151">
              <g id="Group_3150" data-name="Group 3150">
                <path
                  id="Path_6321"
                  data-name="Path 6321"
                  d="M429.711,280.649a7.736,7.736,0,0,0,.44-.727,3.079,3.079,0,0,0-.716-3.6L428,275.035l-3.344-3.017.184-.009-2.094,2.309-2.1,2.3-.009-.184,3.322,3.042,1.418,1.306a3.079,3.079,0,0,0,3.652.37,7.745,7.745,0,0,0,.682-.508,2.336,2.336,0,0,1-.634.585,3.117,3.117,0,0,1-2.321.451,3.2,3.2,0,0,1-1.515-.749L423.8,279.65l-3.345-3.017-.1-.087.088-.1,2.1-2.31,2.1-2.3.088-.1.1.088,3.322,3.042,1.418,1.306a3.208,3.208,0,0,1,.891,1.437,3.116,3.116,0,0,1-.228,2.353A2.333,2.333,0,0,1,429.711,280.649Z"
                  transform="translate(-420.365 -271.737)"
                  fill="#58595b"
                />
              </g>
            </g>
          </g>
          <g id="Group_3167" data-name="Group 3167">
            <g id="Group_3161" data-name="Group 3161">
              <g
                id="Group_3153"
                data-name="Group 3153"
                transform="translate(7.845 0.133)"
              >
                <path
                  id="Path_6322"
                  data-name="Path 6322"
                  d="M385.846,232.139l-8.209-6.789a1.514,1.514,0,0,1-.2-2.131h0a1.514,1.514,0,0,1,2.131-.2l8.209,6.789Z"
                  transform="translate(-377.088 -222.67)"
                  fill="#deefed"
                />
              </g>
              <g
                id="Group_3154"
                data-name="Group 3154"
                transform="translate(0.131 10.05)"
              >
                <path
                  id="Path_6323"
                  data-name="Path 6323"
                  d="M371.623,250.425l-8.209-6.789a1.514,1.514,0,0,1-.2-2.131h0a1.513,1.513,0,0,1,2.131-.2l8.209,6.789Z"
                  transform="translate(-362.864 -240.956)"
                  fill="#deefed"
                />
              </g>
              <g
                id="Group_3155"
                data-name="Group 3155"
                transform="translate(27.845 0.133)"
                opacity="0.6"
              >
                <path
                  id="Path_6324"
                  data-name="Path 6324"
                  d="M385.846,232.139l-8.209-6.789a1.514,1.514,0,0,1-.2-2.131h0a1.514,1.514,0,0,1,2.131-.2l8.209,6.789Z"
                  transform="translate(-397.088 -222.67)"
                  fill="#b5b5b5"
                />
              </g>
              <g
                id="Group_3156"
                data-name="Group 3156"
                transform="translate(20.131 10.05)"
                opacity="0.6"
              >
                <path
                  id="Path_6325"
                  data-name="Path 6325"
                  d="M371.623,250.425l-8.209-6.789a1.514,1.514,0,0,1-.2-2.131h0a1.513,1.513,0,0,1,2.131-.2l8.209,6.789Z"
                  transform="translate(-382.864 -240.956)"
                  fill="#b5b5b5"
                />
              </g>
              <g
                id="Group_3158"
                data-name="Group 3158"
                transform="translate(7.714)"
              >
                <g id="Group_3157" data-name="Group 3157">
                  <path
                    id="Path_6326"
                    data-name="Path 6326"
                    d="M385.736,232.027a1.359,1.359,0,0,1,.127-.179l.368-.476,1.373-1.73.011.114-3.855-3.138-2.414-1.983-1.3-1.068-.666-.549a1.4,1.4,0,0,0-.725-.315,1.38,1.38,0,0,0-1.506,1.7,1.336,1.336,0,0,0,.291.566,4.93,4.93,0,0,0,.5.441l1.017.848,3.516,2.958,2.381,2.026.652.566a2.091,2.091,0,0,1,.229.215,1.4,1.4,0,0,1-.226-.161l-.654-.515-2.423-1.946-3.609-2.934-1.042-.855a4.954,4.954,0,0,1-.536-.473,1.583,1.583,0,0,1-.349-.671,1.643,1.643,0,0,1,1.792-2.033,1.661,1.661,0,0,1,.862.374l.665.55,1.292,1.073,2.4,2,3.807,3.2.063.053-.053.061-1.472,1.71-.386.437C385.782,231.988,385.737,232.034,385.736,232.027Z"
                    transform="translate(-376.847 -222.425)"
                    fill="#263238"
                  />
                </g>
              </g>
              <g
                id="Group_3160"
                data-name="Group 3160"
                transform="translate(0 9.917)"
              >
                <g id="Group_3159" data-name="Group 3159">
                  <path
                    id="Path_6327"
                    data-name="Path 6327"
                    d="M371.512,250.313a1.276,1.276,0,0,1,.126-.179l.368-.476,1.373-1.73.011.114-3.855-3.138-2.415-1.983-1.3-1.068-.666-.549a1.381,1.381,0,0,0-2.231,1.39,1.335,1.335,0,0,0,.291.566,4.862,4.862,0,0,0,.5.44l1.017.849,3.517,2.958,2.38,2.026.652.565a2.237,2.237,0,0,1,.23.215,1.326,1.326,0,0,1-.226-.162l-.655-.515-2.423-1.946-3.609-2.935-1.042-.855a4.938,4.938,0,0,1-.536-.473,1.6,1.6,0,0,1-.349-.671,1.645,1.645,0,0,1,2.654-1.659l.665.55,1.292,1.072,2.4,2,3.806,3.2.063.053-.053.061-1.472,1.71-.387.436C371.558,250.274,371.513,250.32,371.512,250.313Z"
                    transform="translate(-362.623 -240.711)"
                    fill="#263238"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_3165"
              data-name="Group 3165"
              transform="translate(5.488 3.627)"
            >
              <g
                id="Group_3162"
                data-name="Group 3162"
                transform="translate(0.189 0.163)"
              >
                <path
                  id="Path_6328"
                  data-name="Path 6328"
                  d="M402.673,243.809l.217-.262a2.9,2.9,0,0,0-4.032-4.124l-12.018-10.01-13.748,16.448,12.3,9.78a2.9,2.9,0,0,0,.589,3.8h0a2.9,2.9,0,0,0,4.035-.335,8.365,8.365,0,0,0,11.561-1.449l1.973-2.464a8.362,8.362,0,0,0-.877-11.384Z"
                  transform="translate(-373.092 -229.413)"
                  fill="#58595b"
                />
              </g>
              <g id="Group_3164" data-name="Group 3164">
                <g id="Group_3163" data-name="Group 3163">
                  <path
                    id="Path_6329"
                    data-name="Path 6329"
                    d="M402.513,243.671a1.142,1.142,0,0,1,.1.094l.288.275a3.965,3.965,0,0,1,.438.479l.27.323.271.4a7.948,7.948,0,0,1,1.059,2.261,8.367,8.367,0,0,1-1.207,7.228c-.44.626-.952,1.215-1.452,1.86a16.25,16.25,0,0,1-1.661,1.918,8.371,8.371,0,0,1-5.023,2.222,8.489,8.489,0,0,1-5.817-1.661l.164-.017a3.027,3.027,0,0,1-4.207.353l-.009-.008.009.008a3.032,3.032,0,0,1-.618-3.97l.028.171-9.52-7.565-2.78-2.211-.107-.085.087-.1L386.592,229.2l.074-.088.088.074,11.993,10.04h-.1a2.982,2.982,0,0,1,1.891-.63,2.951,2.951,0,0,1,2.893,2.987,2.882,2.882,0,0,1-.536,1.635,3.916,3.916,0,0,1-.28.341,1.4,1.4,0,0,1-.106.113c-.014,0,.13-.161.352-.478a2.894,2.894,0,0,0,.489-1.612,2.845,2.845,0,0,0-4.607-2.237l-.048.038-.048-.04L386.6,229.364l.162-.015-13.734,16.46-.02-.19,2.781,2.211,9.515,7.571.092.073-.064.1a2.777,2.777,0,0,0,.561,3.636l.009.008-.009-.008a2.762,2.762,0,0,0,2.046.621,2.794,2.794,0,0,0,1.818-.938l.074-.085.09.068a8.265,8.265,0,0,0,5.654,1.631,8.166,8.166,0,0,0,4.9-2.147,16.052,16.052,0,0,0,1.651-1.888c.5-.638,1.024-1.235,1.456-1.843a8.267,8.267,0,0,0,1.242-7.1,8,8,0,0,0-1.016-2.251l-.261-.4-.26-.326a4.519,4.519,0,0,0-.427-.5l-.273-.291a.759.759,0,0,1-.083-.09Z"
                    transform="translate(-372.743 -229.113)"
                    fill="#58595b"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_3166"
              data-name="Group 3166"
              transform="translate(11.647 11.51)"
            >
              <rect
                id="Rectangle_2352"
                data-name="Rectangle 2352"
                width="11.001"
                height="2.155"
                transform="translate(1.374) rotate(39.564)"
                fill="#e0e0e0"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_3170"
          data-name="Group 3170"
          transform="translate(22.538 18.185)"
        >
          <g id="Group_3169" data-name="Group 3169">
            <path
              id="Path_6330"
              data-name="Path 6330"
              d="M416.9,255.958a4.364,4.364,0,0,1-.452.643c-.3.4-.754.98-1.317,1.687-1.126,1.413-2.714,3.338-4.488,5.447l-4.547,5.4-1.383,1.631a4.39,4.39,0,0,1-.533.578,4.522,4.522,0,0,1,.452-.643c.3-.4.754-.98,1.318-1.686,1.126-1.413,2.714-3.338,4.488-5.448l4.547-5.4,1.382-1.632a4.48,4.48,0,0,1,.533-.577Z"
              transform="translate(-404.181 -255.957)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3172"
          data-name="Group 3172"
          transform="translate(17.782 13.799)"
        >
          <g id="Group_3171" data-name="Group 3171">
            <path
              id="Path_6331"
              data-name="Path 6331"
              d="M409.073,247.87a5.051,5.051,0,0,1-.491.674c-.329.424-.815,1.029-1.421,1.771-1.213,1.485-2.917,3.514-4.816,5.738l-4.875,5.687-1.486,1.716a4.934,4.934,0,0,1-.571.608,5,5,0,0,1,.491-.674c.329-.424.815-1.028,1.422-1.771,1.213-1.485,2.917-3.514,4.816-5.739l4.874-5.687,1.486-1.716a4.983,4.983,0,0,1,.571-.607Z"
              transform="translate(-395.411 -247.869)"
              fill="#263238"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3527"
        data-name="Group 3527"
        transform="translate(336.791 239.504)"
      >
        <g id="Group_3348" data-name="Group 3348">
          <g id="Group_3333" data-name="Group 3333">
            <g id="Group_3332" data-name="Group 3332">
              <path
                id="Path_6395"
                data-name="Path 6395"
                d="M124.9,243.97a32.646,32.646,0,0,1-7.015-.762,29.809,29.809,0,0,1-11.409-4.712,36.271,36.271,0,0,1-11.091,2.017c-12.3.331-23.43-3.514-37.084-14.565-.69-1.468-1.35-3.025-1.984-4.659,13.139,10.635,27.366,16.836,39,16.523a33.825,33.825,0,0,0,8.794-1.386c-3.174-3.307-4.77-7.629-4.75-12.877.021-5.758,2.47-8.365,4.521-9.538,2.9-1.662,6.871-1.433,10.352.6a11.722,11.722,0,0,1,6.108,10.129,11.148,11.148,0,0,1-4.38,8.661,33.228,33.228,0,0,1-6.45,3.876,32.108,32.108,0,0,0,21.46,3.392c11.557-2.293,21.209-6.394,28.687-12.187A46.608,46.608,0,0,0,177.838,190.1v-.05a42.177,42.177,0,0,0-19.888-33.269c-9.907-5.938-22.6-7.566-35.729-4.582-8.163,1.855-19.269,6.254-25.816,16.683-7.051,11.233-5.138,25.18.677,31.659,4.15,4.624,12.1,2.957,18.492,1.618.722-.151,1.426-.3,2.105-.434,6.874-1.366,34.753-14.442,34.861-14.48l.591,2.222c-.446.157-27.861,13.5-34.925,14.908-.671.133-1.365.279-2.078.428-7.068,1.482-15.867,3.326-21.056-2.457-3.756-4.185-5.843-10.225-5.878-17.008a34.163,34.163,0,0,1,4.924-17.892c7.04-11.213,18.843-15.913,27.506-17.881,13.813-3.138,27.209-1.4,37.716,4.9a44.951,44.951,0,0,1,21.2,35.428,49.3,49.3,0,0,1-19.22,40.72c-7.813,6.052-17.844,10.325-29.815,12.7a33.826,33.826,0,0,1-6.6.657ZM108.247,215.6a6.054,6.054,0,0,0-3.026.753c-2.055,1.176-3.148,3.667-3.161,7.2-.019,5.056,1.568,8.969,4.893,11.875a30.911,30.911,0,0,0,7.353-4.174,8.428,8.428,0,0,0,3.333-6.536,9.055,9.055,0,0,0-4.766-7.785,9.313,9.313,0,0,0-4.626-1.333Z"
                transform="translate(-56.32 -148.041)"
                fill="#58595b"
              />
            </g>
          </g>
          <g
            id="Group_3335"
            data-name="Group 3335"
            transform="translate(46.533 86.918)"
          >
            <g id="Group_3334" data-name="Group 3334">
              <path
                id="Path_6396"
                data-name="Path 6396"
                d="M147.933,313.252a3.219,3.219,0,0,1-1.018-.493,18.97,18.97,0,0,1-2.2-1.61,17.282,17.282,0,0,1-1.957-1.9,2.958,2.958,0,0,1-.635-.937c.034-.022.288.32.774.81s1.184,1.139,1.989,1.821,1.565,1.26,2.129,1.657A9.111,9.111,0,0,1,147.933,313.252Z"
                transform="translate(-142.124 -308.313)"
                fill="#263238"
              />
            </g>
          </g>
          <g
            id="Group_3337"
            data-name="Group 3337"
            transform="translate(48.57 85.174)"
          >
            <g id="Group_3336" data-name="Group 3336">
              <path
                id="Path_6397"
                data-name="Path 6397"
                d="M152.49,310.2a3.329,3.329,0,0,1-1.219-.31,10.214,10.214,0,0,1-2.6-1.559,12.733,12.733,0,0,1-2.123-2.164,4.009,4.009,0,0,1-.663-1.066,10.808,10.808,0,0,1,.809.947,16.655,16.655,0,0,0,2.144,2.074,12.476,12.476,0,0,0,2.509,1.594C152.037,310.038,152.5,310.154,152.49,310.2Z"
                transform="translate(-145.881 -305.098)"
                fill="#263238"
              />
            </g>
          </g>
          <g
            id="Group_3339"
            data-name="Group 3339"
            transform="translate(43.57 69.27)"
          >
            <g id="Group_3338" data-name="Group 3338">
              <path
                id="Path_6398"
                data-name="Path 6398"
                d="M137.444,287.431a1.584,1.584,0,0,1-.17-.44,11.1,11.1,0,0,1-.313-1.245,16.084,16.084,0,0,1,.586-8.373,11.125,11.125,0,0,1,.484-1.189,1.6,1.6,0,0,1,.229-.412c.041.018-.221.633-.534,1.66a18.442,18.442,0,0,0-.58,8.282C137.313,286.773,137.488,287.419,137.444,287.431Z"
                transform="translate(-136.66 -275.771)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3341"
            data-name="Group 3341"
            transform="translate(28.809 89.385)"
          >
            <g id="Group_3340" data-name="Group 3340">
              <path
                id="Path_6399"
                data-name="Path 6399"
                d="M118.239,314.115a4.8,4.8,0,0,1-1.306.226,14.237,14.237,0,0,1-6.3-.894,4.786,4.786,0,0,1-1.191-.58,10.129,10.129,0,0,1,1.254.4,17.931,17.931,0,0,0,6.224.883,10.122,10.122,0,0,1,1.319-.035Z"
                transform="translate(-109.442 -312.862)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3343"
            data-name="Group 3343"
            transform="translate(105.637 72.666)"
          >
            <g id="Group_3342" data-name="Group 3342">
              <path
                id="Path_6400"
                data-name="Path 6400"
                d="M257.843,282.034a6.325,6.325,0,0,1-.792,1.181,28.683,28.683,0,0,1-4.781,4.9,6.332,6.332,0,0,1-1.161.822,9.146,9.146,0,0,1,1.039-.966c.652-.581,1.539-1.4,2.469-2.352s1.723-1.86,2.287-2.527a9.222,9.222,0,0,1,.939-1.058Z"
                transform="translate(-251.109 -282.033)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3345"
            data-name="Group 3345"
            transform="translate(33.469 25.869)"
          >
            <g id="Group_3344" data-name="Group 3344">
              <path
                id="Path_6401"
                data-name="Path 6401"
                d="M119.586,214.91a4.252,4.252,0,0,1-.275-.719,20.521,20.521,0,0,1-.57-2.022,26.721,26.721,0,0,1-.533-3.072,27.4,27.4,0,0,1,.2-7.62,26.663,26.663,0,0,1,.693-3.041,20.55,20.55,0,0,1,.676-1.989,4.253,4.253,0,0,1,.313-.7,4.23,4.23,0,0,1-.215.739c-.161.467-.367,1.152-.592,2a30.282,30.282,0,0,0-.632,3.029,29.463,29.463,0,0,0-.2,7.554,30.346,30.346,0,0,0,.472,3.058c.18.863.35,1.558.485,2.033a4.229,4.229,0,0,1,.178.75Z"
                transform="translate(-118.035 -195.743)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3347"
            data-name="Group 3347"
            transform="translate(36.739 20.694)"
          >
            <g id="Group_3346" data-name="Group 3346">
              <path
                id="Path_6402"
                data-name="Path 6402"
                d="M125.626,186.2a9.472,9.472,0,0,1-1.553,2.764,9.471,9.471,0,0,1,1.553-2.764Z"
                transform="translate(-124.065 -186.199)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_3547"
        data-name="Group 3547"
        transform="translate(431.947 256.081)"
      >
        <g id="Group_3544" data-name="Group 3544">
          <g id="Group_3531" data-name="Group 3531">
            <g
              id="Group_3528"
              data-name="Group 3528"
              transform="translate(0.128 0.11)"
            >
              <path
                id="Path_6428"
                data-name="Path 6428"
                d="M271.424,205.1,266.6,180.315a1.858,1.858,0,1,0-3.647.709l.219,1.126L253.2,184.6a6.657,6.657,0,0,0-4.657,4.157l-6.016,1.48a3.887,3.887,0,0,0-2.845,4.7l.541,2.2-5.887,1.448a3.047,3.047,0,1,0,1.456,5.918l5.887-1.449.418,1.7a3.886,3.886,0,0,0,4.7,2.845l6.125-1.507-.011-.045a6.649,6.649,0,0,0,5.909,1.4l8.85-2.177.1.532a1.858,1.858,0,1,0,3.647-.709Z"
                transform="translate(-232.018 -178.811)"
                fill="#58595b"
              />
            </g>
            <g id="Group_3530" data-name="Group 3530">
              <g id="Group_3529" data-name="Group 3529">
                <path
                  id="Path_6429"
                  data-name="Path 6429"
                  d="M271.316,205a1.407,1.407,0,0,1,.048.418,1.849,1.849,0,0,1-.424,1.149,1.9,1.9,0,0,1-1.832.652,1.937,1.937,0,0,1-1.1-.661,1.879,1.879,0,0,1-.343-.633c-.07-.243-.1-.484-.159-.736l.073.046-6.5,1.623-1.944.484a7.586,7.586,0,0,1-2.12.3,6.773,6.773,0,0,1-4.276-1.608l.166-.1.011.045.025.1-.1.024c-2.068.5-4.23,1.075-6.562,1.6a4.013,4.013,0,0,1-3.4-1.193,3.924,3.924,0,0,1-.957-1.655c-.17-.618-.31-1.233-.468-1.859l.147.089-4.963,1.226c-.43.1-.833.227-1.3.294a3.175,3.175,0,0,1-3.126-4.754,3.32,3.32,0,0,1,2.327-1.567l5.542-1.364-.1.161-.343-1.392a9.361,9.361,0,0,1-.3-1.48,4.016,4.016,0,0,1,2.087-3.811,5.275,5.275,0,0,1,1.408-.486l5.567-1.368-.092.082a6.865,6.865,0,0,1,2.808-3.424,7,7,0,0,1,2.026-.831l2.06-.5,7.831-1.919-.087.137-.171-.881a2.369,2.369,0,0,1-.058-.92,1.968,1.968,0,0,1,3.861-.153l1.185,6.135c.739,3.85,1.383,7.216,1.917,9.994l1.222,6.454.314,1.706.075.439a.853.853,0,0,1,.02.15.933.933,0,0,1-.038-.146l-.094-.435c-.081-.394-.2-.963-.349-1.7l-1.286-6.442-1.97-9.983-1.206-6.122a1.744,1.744,0,0,0-3.421.138,2.127,2.127,0,0,0,.059.841q.086.438.172.881l.022.11-.109.027-7.827,1.934a14.159,14.159,0,0,0-4.01,1.311,6.608,6.608,0,0,0-2.7,3.3l-.024.065-.068.017L242.9,190.18a5.028,5.028,0,0,0-1.344.462A3.751,3.751,0,0,0,239.6,194.2a9.144,9.144,0,0,0,.293,1.435l.342,1.392.032.13-.13.031-5.542,1.363a3.048,3.048,0,0,0-2.163,1.443,2.917,2.917,0,0,0,2.868,4.369c.41-.057.847-.192,1.267-.287l4.965-1.217.118-.029.03.118c.156.622.3,1.251.462,1.85a3.691,3.691,0,0,0,.9,1.559,3.783,3.783,0,0,0,3.2,1.131c2.284-.507,4.483-1.081,6.542-1.573l-.075.124-.011-.045-.075-.308.241.206a6.58,6.58,0,0,0,4.147,1.578,7.392,7.392,0,0,0,2.075-.281l1.946-.473,6.51-1.578.06-.014.013.061c.052.249.087.5.15.725a1.765,1.765,0,0,0,.317.6,1.828,1.828,0,0,0,3.242-1.074C271.342,205.144,271.3,205,271.316,205Z"
                  transform="translate(-231.782 -178.608)"
                  fill="#263238"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_3533"
            data-name="Group 3533"
            transform="translate(31.063 3.493)"
          >
            <g id="Group_3532" data-name="Group 3532">
              <path
                id="Path_6430"
                data-name="Path 6430"
                d="M294,208.083c-.072.015-1.235-5.129-2.6-11.488s-2.409-11.53-2.337-11.545,1.235,5.128,2.6,11.49S294.07,208.067,294,208.083Z"
                transform="translate(-289.061 -185.049)"
                fill="#263238"
              />
            </g>
          </g>
          <g
            id="Group_3535"
            data-name="Group 3535"
            transform="translate(16.663 10.025)"
          >
            <g id="Group_3534" data-name="Group 3534">
              <path
                id="Path_6431"
                data-name="Path 6431"
                d="M266.869,214.416c-.072.018-1.1-3.846-2.308-8.628s-2.12-8.676-2.049-8.694,1.1,3.844,2.308,8.629S266.94,214.4,266.869,214.416Z"
                transform="translate(-262.508 -197.094)"
                fill="#263238"
              />
            </g>
          </g>
          <g
            id="Group_3537"
            data-name="Group 3537"
            transform="translate(8.327 18.436)"
          >
            <g id="Group_3536" data-name="Group 3536">
              <path
                id="Path_6432"
                data-name="Path 6432"
                d="M248.6,218.522a13.392,13.392,0,0,1-.858-2.929,25.307,25.307,0,0,1-.6-2.989,13.4,13.4,0,0,1,.858,2.929,25.364,25.364,0,0,1,.6,2.989Z"
                transform="translate(-247.137 -212.604)"
                fill="#263238"
              />
            </g>
          </g>
          <g
            id="Group_3539"
            data-name="Group 3539"
            transform="translate(8.481 11.471)"
          >
            <g id="Group_3538" data-name="Group 3538">
              <path
                id="Path_6433"
                data-name="Path 6433"
                d="M254.078,199.773a14.408,14.408,0,0,1-1.514.323c-.465.1-1.02.223-1.624.39a8.225,8.225,0,0,0-1.876.74,2.583,2.583,0,0,0-1.317,1.383,4.069,4.069,0,0,0-.034,1.618c.12.941.277,1.514.236,1.524a5.05,5.05,0,0,1-.423-1.5,3.982,3.982,0,0,1-.016-1.714,1.983,1.983,0,0,1,.54-.894,4.388,4.388,0,0,1,.881-.652,7.814,7.814,0,0,1,1.946-.746c.618-.156,1.18-.262,1.654-.338a6.5,6.5,0,0,1,1.547-.134Z"
                transform="translate(-247.42 -199.76)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3541"
            data-name="Group 3541"
            transform="translate(17.882 4.904)"
          >
            <g id="Group_3540" data-name="Group 3540">
              <path
                id="Path_6434"
                data-name="Path 6434"
                d="M276.281,187.658a2.1,2.1,0,0,1-.514.155c-.334.082-.819.194-1.42.325l-4.7.988a9.89,9.89,0,0,0-2.456.859,5.121,5.121,0,0,0-1.62,1.334,4.5,4.5,0,0,0-.81,1.764,1.361,1.361,0,0,1,.042-.538,3.613,3.613,0,0,1,.617-1.34,5.08,5.08,0,0,1,1.653-1.436,9.566,9.566,0,0,1,2.513-.9c1.842-.4,3.509-.69,4.72-.913.605-.108,1.1-.19,1.436-.242a2.081,2.081,0,0,1,.539-.056Z"
                transform="translate(-264.755 -187.651)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_3543"
            data-name="Group 3543"
            transform="translate(0.72 19.781)"
          >
            <g id="Group_3542" data-name="Group 3542">
              <path
                id="Path_6435"
                data-name="Path 6435"
                d="M239.692,215.089a2.633,2.633,0,0,1-1.047.416c-.676.183-1.638.26-2.651.475a5.434,5.434,0,0,0-1.363.476,3.471,3.471,0,0,0-.927.709,4.745,4.745,0,0,0-.587.919,1.625,1.625,0,0,1,.443-1.041,3.308,3.308,0,0,1,.952-.8,5.125,5.125,0,0,1,1.424-.521c1.048-.221,2-.266,2.669-.4A10.783,10.783,0,0,1,239.692,215.089Z"
                transform="translate(-233.11 -215.083)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_3546"
          data-name="Group 3546"
          transform="translate(31.407 0.609)"
        >
          <g id="Group_3545" data-name="Group 3545">
            <path
              id="Path_6436"
              data-name="Path 6436"
              d="M291.751,179.812c0,.041-.2.037-.484.115a1.816,1.816,0,0,0-1.381,1.62c-.031.3,0,.486-.037.5s-.134-.171-.152-.5a1.738,1.738,0,0,1,1.535-1.8C291.561,179.707,291.757,179.781,291.751,179.812Z"
              transform="translate(-289.694 -179.732)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3566"
        data-name="Group 3566"
        transform="translate(475.037 244.545)"
      >
        <g
          id="Group_3549"
          data-name="Group 3549"
          transform="translate(25.684 8.569)"
        >
          <g id="Group_3548" data-name="Group 3548">
            <path
              id="Path_6437"
              data-name="Path 6437"
              d="M402.3,173.138a.412.412,0,0,1-.1.065l-.307.168-1.2.626-4.446,2.271-14.926,7.519-.1.05-.066-.094-1.3-1.842L378,179.277l.175.038-13.908,8.034-4.145,2.362-1.125.627-.294.157a.385.385,0,0,1-.1.046.4.4,0,0,1,.092-.068l.282-.178,1.1-.667,4.1-2.432,13.865-8.11.1-.061.07.1,1.855,2.624,1.3,1.843-.166-.043,14.96-7.441,4.482-2.2,1.218-.582.319-.146a.421.421,0,0,1,.12-.042Z"
              transform="translate(-358.598 -173.138)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3551"
          data-name="Group 3551"
          transform="translate(34.8 28.155)"
        >
          <g id="Group_3550" data-name="Group 3550">
            <path
              id="Path_6438"
              data-name="Path 6438"
              d="M406.039,209.257a1.434,1.434,0,0,1-.3.082l-.856.187-3.175.646-10.635,2.065-.132.026-.023-.133c-.075-.419-.154-.864-.234-1.315-.053-.3-.106-.594-.155-.87l.154.108c-4.315.741-8.137,1.643-10.872,2.365-1.368.36-2.467.667-3.226.881l-.873.242a1.443,1.443,0,0,1-.31.068,1.467,1.467,0,0,1,.294-.118l.86-.289c.75-.244,1.842-.579,3.207-.962a109.67,109.67,0,0,1,10.874-2.45l.131-.023.023.131c.049.276.1.572.155.87l.234,1.315-.156-.107,10.651-1.982,3.191-.565.866-.14a1.358,1.358,0,0,1,.307-.032Z"
              transform="translate(-375.406 -209.254)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3553"
          data-name="Group 3553"
          transform="translate(22.663 9.336)"
        >
          <g id="Group_3552" data-name="Group 3552">
            <path
              id="Path_6439"
              data-name="Path 6439"
              d="M359.439,174.552c.066.033-1.315,3.014-3.085,6.659s-3.258,6.574-3.324,6.542,1.314-3.013,3.085-6.659S359.373,174.52,359.439,174.552Z"
              transform="translate(-353.028 -174.552)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3555"
          data-name="Group 3555"
          transform="translate(29.864 23.951)"
        >
          <g id="Group_3554" data-name="Group 3554">
            <path
              id="Path_6440"
              data-name="Path 6440"
              d="M381.709,201.506c.021.071-3.411,1.135-7.663,2.377s-7.719,2.192-7.739,2.121,3.41-1.135,7.665-2.377S381.688,201.435,381.709,201.506Z"
              transform="translate(-366.306 -201.502)"
              fill="#263238"
            />
          </g>
        </g>
        <g id="Group_3557" data-name="Group 3557" transform="translate(16.859)">
          <g id="Group_3556" data-name="Group 3556">
            <path
              id="Path_6441"
              data-name="Path 6441"
              d="M344.2,157.337a3.543,3.543,0,0,1,.221.555l.549,1.583,1.869,5.6.054.162h-.172l-4.236-.085.133-.157.313,1.682c.694,3.822,1.322,7.279,1.784,9.827l.511,2.984.126.813a1.228,1.228,0,0,1,.026.287,1.153,1.153,0,0,1-.079-.278l-.175-.8c-.148-.738-.349-1.742-.594-2.968-.483-2.545-1.138-6-1.861-9.813-.107-.576-.212-1.144-.311-1.682l-.03-.161h.163l4.235.108-.118.158-1.777-5.631-.485-1.6a3.411,3.411,0,0,1-.146-.584Z"
              transform="translate(-342.324 -157.337)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3559"
          data-name="Group 3559"
          transform="translate(0 35.052)"
        >
          <g id="Group_3558" data-name="Group 3558">
            <path
              id="Path_6442"
              data-name="Path 6442"
              d="M311.238,227.784c-.031-.067,2.72-1.422,6.144-3.026s6.227-2.852,6.258-2.785-2.719,1.421-6.145,3.027S311.269,227.85,311.238,227.784Z"
              transform="translate(-311.237 -221.97)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3561"
          data-name="Group 3561"
          transform="translate(12.784 38.919)"
        >
          <g id="Group_3560" data-name="Group 3560">
            <path
              id="Path_6443"
              data-name="Path 6443"
              d="M334.813,234.978a54.98,54.98,0,0,1,4.9-5.875A54.831,54.831,0,0,1,334.813,234.978Z"
              transform="translate(-334.811 -229.102)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3563"
          data-name="Group 3563"
          transform="translate(19.675 39.854)"
        >
          <g id="Group_3562" data-name="Group 3562">
            <path
              id="Path_6444"
              data-name="Path 6444"
              d="M347.526,254.391c-.074-.008.4-5.289,1.05-11.8s1.243-11.777,1.316-11.77-.4,5.288-1.05,11.8S347.6,254.4,347.526,254.391Z"
              transform="translate(-347.518 -230.826)"
              fill="#263238"
            />
          </g>
        </g>
        <g
          id="Group_3565"
          data-name="Group 3565"
          transform="translate(10.267 16.696)"
        >
          <g id="Group_3564" data-name="Group 3564">
            <path
              id="Path_6445"
              data-name="Path 6445"
              d="M330.177,188.124a24.265,24.265,0,0,1,2.263,4.553,24.265,24.265,0,0,1-2.263-4.553Z"
              transform="translate(-330.17 -188.124)"
              fill="#263238"
            />
          </g>
        </g>
      </g>
      <path
        id="Path_7497"
        data-name="Path 7497"
        d="M24.9-15.308c0-6.407-.086-9.46-3.1-12.47a10.684,10.684,0,0,0-7.912-3.1,10.684,10.684,0,0,0-7.912,3.1c-3.01,3.01-3.1,6.063-3.1,12.47s.086,9.46,3.1,12.47a10.684,10.684,0,0,0,7.912,3.1,10.684,10.684,0,0,0,7.912-3.1C24.811-5.848,24.9-8.9,24.9-15.308Zm-4.644,0c0,6.063-.258,7.912-1.806,9.5A6.19,6.19,0,0,1,13.889-3.87,6.19,6.19,0,0,1,9.331-5.805c-1.5-1.634-1.806-3.44-1.806-9.5s.3-7.912,1.806-9.546a6.155,6.155,0,0,1,4.558-1.892,6.155,6.155,0,0,1,4.558,1.892C20-23.263,20.253-21.371,20.253-15.308Zm28.165,4.386c0-3.655-.516-6.407-2.58-8.557a8.824,8.824,0,0,0-6.536-2.58,8.72,8.72,0,0,0-6.493,2.58c-2.064,2.15-2.58,4.9-2.58,8.557,0,3.7.516,6.45,2.58,8.6A8.72,8.72,0,0,0,39.3.258a8.824,8.824,0,0,0,6.536-2.58C47.9-4.472,48.418-7.224,48.418-10.922Zm-4.386,0c0,2.365-.172,4.644-1.505,5.977A4.476,4.476,0,0,1,39.3-3.655a4.376,4.376,0,0,1-3.182-1.29c-1.333-1.333-1.505-3.612-1.505-5.977s.172-4.644,1.505-5.977A4.343,4.343,0,0,1,39.3-18.146,4.444,4.444,0,0,1,42.527-16.9C43.86-15.566,44.032-13.287,44.032-10.922Zm28.165,0c0-3.569-.344-7.052-2.451-9.2a7.469,7.469,0,0,0-5.332-1.935,6.756,6.756,0,0,0-5.762,2.58V-21.8h-4.3V8.815h4.386V-2.236A6.667,6.667,0,0,0,64.457.258a7.361,7.361,0,0,0,5.289-1.935C71.853-3.827,72.2-7.353,72.2-10.922Zm-4.386,0c0,3.87-.559,7.267-4.515,7.267-4,0-4.558-3.4-4.558-7.267s.559-7.224,4.558-7.224C67.252-18.146,67.811-14.792,67.811-10.922ZM94.256-6.665c0-3.827-2.365-5.805-6.88-6.192l-3.053-.258c-2.15-.215-3.01-.989-3.01-2.494,0-1.677,1.29-2.8,3.956-2.8a8.235,8.235,0,0,1,5.375,1.591l2.752-2.8c-2.021-1.763-4.773-2.451-8.084-2.451-4.558,0-8.213,2.365-8.213,6.665,0,3.827,2.322,5.633,6.45,6.02l3.483.3c2.107.215,2.924,1.075,2.924,2.58,0,2.064-2.064,3.01-4.773,3.01a8.464,8.464,0,0,1-6.278-2.279L76.024-2.881C78.6-.344,81.614.258,85.183.258,90.386.258,94.256-2.107,94.256-6.665Z"
        transform="translate(463 226)"
        fill="#58595b"
      />
    </g>
  </svg>
);

export default Oops;
