import { css } from '@emotion/react';

import { family, weight } from './fonts';

export default css`
  /**
  * Roboto Regular
  */
  @font-face {
    font-family: ${family.roboto};
    font-weight: ${weight.regular};
    font-style: normal;
    font-display: swap;
    src: url('${require('@wb/shared/fonts/roboto/Roboto-Regular.ttf').default}')
      format('opentype');
  }

  /**
  * Roboto Medium
  */
  @font-face {
    font-family: ${family.roboto};
    font-weight: ${weight.medium};
    font-style: normal;
    font-display: swap;
    src: url('${require('@wb/shared/fonts/roboto/Roboto-Medium.ttf').default}')
      format('opentype');
  }
`;
