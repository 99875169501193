import React from 'react';
import { useTranslation } from 'react-i18next';

import PageStatus from '../../components/page-status/PageStatus';

import GenericErrorImage from './GenericErrorImage';

const GenericError = () => {
  const { t } = useTranslation('shared-components');

  return (
    <PageStatus image={<GenericErrorImage />} title={t('error-page.message')} />
  );
};

export default GenericError;
