export const WB_SS_ACCESS_TOKEN_NAME = 'whistleblower.accessToken';
export const WB_SS_IDENTITY_TOKEN_NAME = 'whistleblower.identityToken';

export const getAccessToken = () =>
  sessionStorage && sessionStorage.getItem(WB_SS_ACCESS_TOKEN_NAME);

export const saveAccessToken = (token) =>
  sessionStorage && sessionStorage.setItem(WB_SS_ACCESS_TOKEN_NAME, token);

export const clearAccessToken = () =>
  sessionStorage && sessionStorage.removeItem(WB_SS_ACCESS_TOKEN_NAME);

export const getIdentityToken = () =>
  sessionStorage && sessionStorage.getItem(WB_SS_IDENTITY_TOKEN_NAME);

export const saveIdentityToken = (token) =>
  sessionStorage && sessionStorage.setItem(WB_SS_IDENTITY_TOKEN_NAME, token);

export const clearIdentityToken = () =>
  sessionStorage && sessionStorage.removeItem(WB_SS_IDENTITY_TOKEN_NAME);
