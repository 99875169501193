import * as Yup from 'yup';

import getTime from 'date-fns/getTime';

import { DISCLOSURE_FORM_STEPS } from '@wb/frontoffice/state/disclosure-form/useDisclosureForm';

import translationMapper from './translationMapper';
import INPUT_TYPES, {
  DATE_TIME,
  FILE_TYPE,
  RADIO_TYPE,
  SUBSIDIARY_SELECT,
} from './inputTypes';

import {
  validDate,
  validDateTime,
  maxTodayDate,
  maxTodayDateTime,
} from './dateValidations';

Yup.addMethod(Yup.string, 'validDate', validDate);
Yup.addMethod(Yup.string, 'validDateTime', validDateTime);
Yup.addMethod(Yup.string, 'maxTodayDate', maxTodayDate);
Yup.addMethod(Yup.string, 'maxTodayDateTime', maxTodayDateTime);

const CUSTOM_ERROR_FIELDS = ['email'];

const disclosureFormBuilder = (steps, t) => {
  return steps.reduce((acc, definition, index) => {
    const step = DISCLOSURE_FORM_STEPS[index];

    acc[step] = {
      id: index,
      name: definition.name,
      schema: definition.inputs.reduce(
        (acc, definition) => {
          if (!INPUT_TYPES.includes(definition.type)) {
            acc.extraDefinitions[definition.name] = definition;
            return acc;
          }

          const {
            name,
            type,
            pattern,
            required,
            maxLength,
            external,
            visible,
          } = definition;

          acc.inputFields.push({
            name,
            type,
            required,
            external,
            visible,
            ...(maxLength && { maxLength }),
          });

          if (type == DATE_TIME) {
            return setupDateTime(acc, name, required, step, t);
          }

          if (type === RADIO_TYPE || type === SUBSIDIARY_SELECT) {
            acc.inputFields[acc.inputFields.length - 1].options =
              definition.options;
          }

          acc.initialValues[name] = '';
          acc.validationSchema[name] = Yup.string();

          if (pattern) {
            acc.validationSchema[name] = acc.validationSchema[name].matches(
              RegExp(pattern),
              t(
                `forms.errors${
                  CUSTOM_ERROR_FIELDS.includes(name) ? `.${name}` : ''
                }.invalid`,
                {
                  field: t(`${translationMapper(step)}.${name}`),
                }
              )
            );
          }

          if (required && type !== FILE_TYPE) {
            acc.validationSchema[name] = acc.validationSchema[name].required(
              t('forms.errors.required', {
                field: t(`${translationMapper(step)}.${name}`),
              })
            );
          }
          return acc;
        },
        {
          inputFields: [],
          initialValues: {},
          validationSchema: {},
          extraDefinitions: {},
        }
      ),
    };

    acc[step].schema.validationSchema = Yup.object().shape(
      acc[step].schema.validationSchema
    );

    return acc;
  }, {});
};

const setupDateTime = (acc, name, required, step, t) => {
  const dateKey = `${name}-date`;
  const timeKey = `${name}-time`;

  acc.initialValues[dateKey] = '';
  acc.initialValues[timeKey] = '';

  if (required) {
    acc.validationSchema[dateKey] = Yup.string()
      .required(
        t('forms.errors.required', {
          field: t(`${translationMapper(step)}.date`),
        })
      )
      .validDate(t('forms.errors.date.invalid'))
      .maxTodayDate(t('forms.errors.date.future'));

    acc.validationSchema[timeKey] = Yup.string()
      .required(
        t('forms.errors.required', {
          field: t(`${translationMapper(step)}.hours`),
        })
      )
      .validDateTime({
        message: t('forms.errors.time.invalid'),
        ref: Yup.ref(dateKey),
      })
      .maxTodayDateTime({
        message: t('forms.errors.time.future'),
        ref: Yup.ref(dateKey),
      });
  }

  return acc;
};

export const sanitizeSubmitData = (values, files) => {
  const whenDate = values.case['when-date']
    ? new Date(
        `${values.case['when-date'].split('/').reverse().join('/')} ${
          values.case['when-time']
        }`
      )
    : '';

  return {
    disclosureDetails: {
      category: values.subject.topics,
      city: values.case.city,
      country: values.case.country,
      files,
      other: '',
      what: values.case.what,
      when: getTime(whenDate),
      'organizational-subsidiaries': values.case['organizational-subsidiaries'],
    },
    whistleblowerDetails: values.identification,
  };
};

export default disclosureFormBuilder;
