import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  StyledHeading,
  StyledContainer,
  StyledButton,
} from './PageStatus.styles';

const types = {
  default: 'grey-darkest',
  primary: 'primary-color',
  error: 'error-main',
  success: 'success-main',
};

const PageStatus = ({
  as = 'default',
  title,
  message,
  image = null,
  onClick = null,
  buttonLabel,
}) => {
  const type = types[as];
  return (
    <Wrapper>
      {image}
      <StyledHeading as="h3" color={type} style={{ fontSize: '1.8rem' }}>
        {title}
      </StyledHeading>
      <StyledContainer color={type}>{message}</StyledContainer>
      {onClick && (
        <StyledButton size="large" onClick={onClick}>
          {buttonLabel}
        </StyledButton>
      )}
    </Wrapper>
  );
};

PageStatus.propTypes = {
  as: PropTypes.oneOf(Object.keys(types)),
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  image: PropTypes.node,
  onClick: PropTypes.func,
};

export default PageStatus;
