import React from 'react';

import Loader from '@wb/shared/components/loader/Loader';

import { Main } from './PageLoader.styles';

// TODO: Replace when designs arrive
function PageLoader(props) {
  return (
    <Main {...props}>
      <Loader size="large" />
    </Main>
  );
}

export default PageLoader;
