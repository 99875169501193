import React, { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import i18next from 'i18next';

import kyInstance from '@wb/shared/client/kyInstance';

export const CustomerContext = createContext();

export const CustomerProvider = ({ isAdmin = false, ...rest }) => {
  const queryFn = isAdmin ? () => {} : () => kyInstance.get('init').json();

  const { isLoading, error, data } = useQuery('tenantInit', queryFn, {
    suspense: true,
  });
  if (data) {
    const [customerId] = window.location.host.split('.') || null;
    data.customerId = customerId;
  }

  useEffect(() => {
    if (isAdmin) return;
    if (data?.theme) {
      const primaryColor = data.theme.primary;
      const secondaryColor = data.theme.secondary;

      if (primaryColor) {
        document.documentElement.style.setProperty(
          '--palette-primary-color',
          primaryColor
        );
      }

      if (secondaryColor) {
        document.documentElement.style.setProperty(
          '--palette-secondary-color',
          secondaryColor
        );
      }
    }

    if (data?.logo) {
      document.getElementById('apple-favicon')?.setAttribute('href', data.logo);
      document.getElementById('favicon')?.setAttribute('href', data.logo);
    }

    i18next.store?.on('added', function (userLanguage, nameSpace) {
      if (nameSpace === 'shared-common') {
        data?.layoutDefinition?.formDefinition.steps[1].inputs[0].options
          .filter(({ translations }) => translations)
          .forEach(({ name, translations }) =>
            translations.forEach(
              ({ language, fields: { label, description } }) => {
                if (language !== userLanguage) return;
                i18next.addResources(
                  language,
                  'shared-common',
                  {
                    [`topics.${name}`]: label ?? undefined,
                    [`topics.description.${name}`]: description ?? undefined,
                  },
                  { silent: true }
                );
              }
            )
          );
      }
    });
  }, [data]);

  const context = useMemo(
    () => ({
      isLoading,
      error,
      ...data,
    }),
    [data, error, isLoading]
  );

  if (error) {
    throw error;
  }

  return <CustomerContext.Provider value={context} {...rest} />;
};

CustomerProvider.propTypes = {
  isAdmin: PropTypes.bool,
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};

export default useCustomer;
