import { css } from '@emotion/react';

import * as media from '@wb/shared/theme/custom-media';

import { family, weight } from './fonts';

export const fontFamily = `${family.roboto}, sans-serif, -apple-system`;

const sharedTitleStyles = css`
  font-family: ${fontFamily};
  font-weight: ${weight.medium};
  font-weight: ${weight.medium};
  line-height: 1.2;
  letter-spacing: 0.01rem;
  color: var(--palette-grey-darkest);
`;

const sharedTextStyles = css`
  font-family: ${fontFamily};
  font-weight: ${weight.regular};
  letter-spacing: 0.01rem;
  color: var(--palette-grey-darker);
`;

const typography = {
  fontFamily,
  weight,
  large: css`
    ${sharedTitleStyles};

    font-size: 2.6rem;
    line-height: 1.4;
    letter-spacing: 0;

    ${media.mediumMedia} {
      font-size: 4.6rem;
    }
  `,
  h1: css`
    ${sharedTitleStyles};

    font-size: 2.2rem;
    line-height: 1.4;

    ${media.mediumMedia} {
      font-size: 2.8rem;
      line-height: 1.2;
    }
  `,
  h2: css`
    ${sharedTitleStyles};

    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: 0.02rem;

    ${media.mediumMedia} {
      line-height: 1.2;
      letter-spacing: 0.01rem;
    }
  `,
  h3: css`
    ${sharedTitleStyles};

    font-size: 1.6rem;
    line-height: 1.4;
    letter-spacing: 0.02rem;

    ${media.mediumMedia} {
      line-height: 1.2;
    }
  `,
  subtitle: css`
    ${sharedTitleStyles};

    font-size: 1.8rem;
    letter-spacing: 0;
  `,
  body1: css`
    ${sharedTextStyles};

    font-size: 1.6rem;
    line-height: 1.4;
  `,
  body2: css`
    ${sharedTextStyles};

    font-size: 1.4rem;
    line-height: 1.3;
  `,
  small: css`
    ${sharedTextStyles};

    font-weight: ${weight.medium};
    font-size: 1.2rem;
    line-height: 1.4;
    letter-spacing: 0.02rem;
  `,
  a: css`
    ${sharedTextStyles};

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
};

export default typography;
