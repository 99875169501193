import { css } from '@emotion/react';

import sharedGlobalStyles from '@wb/shared/theme/global';

import customProperties from './custom-properties';

export default css`
  ${sharedGlobalStyles};
  ${customProperties};
`;
