import styled from '@emotion/styled';

import typography from '@wb/shared/theme/typography';

const variantStylesMapper = {
  h1: typography.h1,
  h2: typography.h2,
  h3: typography.h3,
  large: typography.large,
  subtitle: typography.subtitle,
};

export const NativeHeading = styled.h1`
  ${({ variant }) => variantStylesMapper[variant]};
`;
